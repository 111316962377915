/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useTradespersonDetails } from 'src/hooks/useTradespersonDetails';

const unauthenticatedRoutes = new Set(['/login', '/']);
let lastTrackedPath = '';

const track = (url: string, tradespersonId: string | undefined) => {
  // dont track auth page
  if (url.startsWith('/auth')) {
    return;
  }
  // dont track a page that requires authentication but has
  // no signed in user due to race conditions etc
  if (!unauthenticatedRoutes.has(url) && !tradespersonId) {
    return;
  }
  // avoid double tracking due to nextjs bug and useEffects
  if (url === lastTrackedPath) {
    return;
  }
  lastTrackedPath = url;
  window.analytics.page(url, undefined, { tradesperson_id: tradespersonId });
};

export const TrackPage = () => {
  const router = useRouter();
  const { tradesperson } = useTradespersonDetails();

  useEffect(() => {
    if (router.isReady) {
      track(router.asPath, tradesperson?.id);
    }
  }, [router.isReady, tradesperson?.id]);
  useEffect(() => {
    const onRouteChangeComplete = (url: string) => {
      track(url, tradesperson?.id);
    };
    track(router.asPath, tradesperson?.id);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [tradesperson?.id]);
  return null;
};
