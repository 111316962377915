import { ApolloLink } from '@apollo/client';

import { operationRequiresAuth } from '../utils';
import { attachHeaderIfRequiredLink } from './attachHeaderIfRequiredLink';
import { onSuccessLink } from './onSuccessLink';
import { retryOnUnauthenticatedLink } from './retryOnUnauthenticatedLink';
import { retryRefreshIfPossible } from './retryRefreshIfPossible';
import { AuthLinkProps } from './types';

export const authLink = (props: AuthLinkProps) => {
  return ApolloLink.split(
    operationRequiresAuth,
    ApolloLink.from([
      onSuccessLink(),
      retryOnUnauthenticatedLink(props),
      retryRefreshIfPossible(props),
      attachHeaderIfRequiredLink(),
    ]),
  );
};
