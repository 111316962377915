import { useState } from 'react';

export const useCounter = () => {
  const [value, setValue] = useState(0);

  const increment = () => {
    setValue((v) => ++v);
  };

  const decrement = () => {
    setValue((v) => --v);
  };

  return { value, increment, decrement };
};
