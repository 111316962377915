import { Icon } from '../Icon';
type buttonType =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'destructivePrimary'
  | 'destructiveSecondary';

export interface CTAProps {
  onClick: () => void;
  children: string | string[] | React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  type?: buttonType;
  extraStyles?: string;
}

const getCTAStyles = (type: buttonType, disabled: boolean = false) => {
  if (disabled) {
    switch (type) {
      case 'primary':
      case 'destructivePrimary':
        return 'bg-cta-disabled text-white cursor-not-allowed';
      case 'secondary':
      case 'destructiveSecondary':
        return 'text-cta-disabled cursor-not-allowed border-2 border-cta-disabled';
      case 'link':
        return 'text-cta-disabled';
      default:
        break;
    }
  } else {
    switch (type) {
      case 'primary':
        return 'bg-cta-action text-white hover:bg-cta-actionHover';
      case 'secondary':
        return 'text-cta-action hover:bg-cta-secondaryHover border-2 border-cta-action';
      case 'link':
        return 'text-cta-active hover:text-cta-linkHover';
      case 'destructivePrimary':
        return 'text-white bg-cta-destructive hover:bg-cta-destructiveHover';
      case 'destructiveSecondary':
        return 'text-cta-destructive hover:text-cta-destructiveHover hover:bg-cta-destructiveSecondaryHover border-2 border-cta-destructive hover:border-cta-destructiveHover';
      default:
        break;
    }
  }
};

const getLoadingStyles = (type: buttonType) => {
  switch (type) {
    case 'primary':
    case 'destructivePrimary':
      return 'fill-white';
    case 'destructiveSecondary':
      return 'fill-cta-destructive';
    default:
      return 'fill-cta-action';
  }
};

export const CTA = ({
  onClick,
  type = 'primary',
  disabled = false,
  loading = false,
  children,
  extraStyles,
}: CTAProps) => {
  const styles = getCTAStyles(type, disabled);
  const loadingStyles = getLoadingStyles(type);
  return (
    <button
      data-testid="cta"
      onClick={onClick}
      disabled={disabled}
      className={`flex gap-x-1 items-center justify-center w-full rounded-lg p-2 transition-colors py-2.5 text-md font-semibold ${styles} ${extraStyles}`}
    >
      {loading && (
        <Icon
          className={loading ? 'spinner' : ''}
          name={loading ? 'spinner' : ''}
          pathFill={loadingStyles}
        />
      )}
      {children}
    </button>
  );
};
