import { PropsWithChildren, useEffect, useState } from 'react';

import { CTA } from '../CTA/CTA';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import styles from './modal.module.css';

export interface ContactModalProps {
  name: string;
  homeNumber?: string;
  mobileNumber?: string;
  onDismiss?: () => void;
}

export const ContactModal = ({
  name,
  homeNumber,
  mobileNumber,
  onDismiss = () => {},
}: ContactModalProps) => {
  return (
    <div className={styles.small} data-testid="contact-modal">
      <div className="flex">
        <button
          onClick={onDismiss}
          className={styles.dismissButton}
          data-testid="contact-modal-button-dismiss-button"
        >
          <Icon name="cross" width={12} height={12} />
        </button>
        <span
          className={styles.iconBackground}
          data-testid="contact-modal-icon"
        >
          <Icon name="telephone" width={16} height={16} />
        </span>
      </div>
      <span className={styles.title} data-testid="confirmation-modal-title">
        {name}
      </span>
      <div
        className="flex flex-col text-center gap-y-4"
        data-testid="contact-modal-content"
      >
        {homeNumber && (
          <div
            className="flex flex-col gap-y-2"
            data-testid="home-number-container"
          >
            <span className="text-modal-contact">Home</span>
            <CopyTooltip copyText={homeNumber}>
              <span data-testid="home-number">{homeNumber}</span>
            </CopyTooltip>
          </div>
        )}
        {mobileNumber && (
          <div className="flex flex-col gap-y-2">
            <span className="text-modal-contact">Mobile</span>
            <CopyTooltip copyText={mobileNumber}>
              <span data-testid="mobile-number">{mobileNumber}</span>
            </CopyTooltip>
          </div>
        )}
      </div>
      <div
        className="flex w-full gap-x-2"
        data-testid="confirmation-modal-done-button"
      >
        <CTA type="secondary" onClick={onDismiss}>
          Done
        </CTA>
      </div>
    </div>
  );
};
interface CopyTooltipProps {
  copyText: string;
}
const CopyTooltip = ({
  copyText,
  children,
}: PropsWithChildren<CopyTooltipProps>) => {
  const [hasCopied, setHasCopied] = useState(false);
  const handleClick = (event: any) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(copyText);
      setHasCopied(true);
    }
    event.stopPropagation();
    event.preventDefault();
  };
  useEffect(() => {
    if (hasCopied) {
      const timeout = setTimeout(() => {
        setHasCopied(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [hasCopied]);
  const tooltipContent = hasCopied ? (
    <div className="flex gap-x-2 items-center" data-testid="copied">
      <Icon name="tick" pathFill="fill-white" />
      Copied
    </div>
  ) : (
    <div
      onClick={handleClick}
      className="cursor-pointer flex gap-x-2"
      data-testid="copy"
    >
      <Icon name="copy" pathFill="fill-white" />
      Copy
    </div>
  );
  return (
    <Tooltip message={tooltipContent} position="top" interactive>
      {children}
    </Tooltip>
  );
};
