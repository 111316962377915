import { EventEmitter, useEvent } from 'kantan-utils';
import { useMemo } from 'react';

export type ApolloEvents = {
  emitForbiddenError: EventEmitter<Error>;
  emitTokenRefreshFailed: EventEmitter<Error>;
  emitNoRefreshToken: EventEmitter<any>;
};

interface UseApolloEventsProps {
  onForbiddenError?: (err: Error) => void;
  onTokenRefreshFailed?: (err: Error) => void;
  onNoRefreshToken?: (extra?: any) => void;
}

export const useApolloEvents = ({
  onForbiddenError,
  onTokenRefreshFailed,
  onNoRefreshToken,
}: UseApolloEventsProps = {}): ApolloEvents => {
  const emitForbiddenError = useEvent('apollo-403-forbidden-response', (err) =>
    onForbiddenError?.(err),
  );

  const emitNoRefreshToken = useEvent('apollo-refresh-token-missing', (extra) =>
    onNoRefreshToken?.(extra),
  );

  const emitTokenRefreshFailed = useEvent(
    'apollo-token-refresh-failed',
    (err) => onTokenRefreshFailed?.(err),
  );

  return useMemo(
    () => ({ emitForbiddenError, emitNoRefreshToken, emitTokenRefreshFailed }),
    [emitForbiddenError, emitNoRefreshToken, emitTokenRefreshFailed],
  );
};
