import { useCallback } from 'react';

import { removeCookie, removeItem, setItem } from 'src/utils/storage';

interface UseAuthMethodsProps {
  onLoginSuccess?: () => void;
  onLogoutSuccess?: () => void;
}

interface UseAuthMethods {
  login: (refreshToken: string, accessToken: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const useAuthMethods = ({
  onLoginSuccess,
  onLogoutSuccess,
}: UseAuthMethodsProps = {}): UseAuthMethods => {
  const login = useCallback(
    async (refreshToken: string, accessToken: string) => {
      setItem('ACCESS_TOKEN', accessToken);
      setItem('REFRESH_TOKEN', refreshToken);

      onLoginSuccess?.();
    },
    [onLoginSuccess],
  );

  const logout = useCallback(async () => {
    removeItem('ACCESS_TOKEN');
    removeItem('REFRESH_TOKEN');
    removeItem('REFRESH_IN_PROGRESS');
    removeCookie('appBannerDismissed');

    onLogoutSuccess?.();
  }, [onLogoutSuccess]);

  return { login, logout };
};
