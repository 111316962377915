import { useEffect, useState } from 'react';

import { addDataLayerObject } from 'src/utils/dataLayer';

import { setUserContext } from '../contexts';
import { User } from '../types';
import { decodeToken } from '../utils';
import { useTokens } from './useTokens';

interface UseUserProps {
  mockUser?: User;
}

interface UseUser {
  user?: User;
  isImpersonating: boolean;
  isInternalUser: boolean;
  isAuthenticated: boolean;
  isAuthReady: boolean;
}

export const useUser = ({ mockUser }: UseUserProps): UseUser => {
  const { accessToken, refreshToken, tokensReady } = useTokens();
  const [user, setUser] = useState<User>();
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [isInternalUser, setIsInternalUser] = useState(false);

  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthReady(tokensReady);
  }, [tokensReady]);

  useEffect(() => {
    setIsAuthenticated(!!refreshToken);
  }, [refreshToken]);

  useEffect(() => {
    const decodedUser = decodeToken(accessToken);
    addDataLayerObject('user_id', decodedUser?.userId);

    setUser(decodedUser);
    setIsInternalUser(decodedUser?.context.is_internal_user === '1');
    setIsImpersonating(!!decodedUser?.extraClaims?.ipb);
    setUserContext(decodedUser?.context);
  }, [accessToken]);

  return {
    user: mockUser ?? user,
    isImpersonating,
    isInternalUser,
    isAuthenticated,
    isAuthReady,
  };
};
