import { Action, ActionType, Alert } from './types';

export const addAlert = (alert: Alert, showOverlay?: boolean): Action => ({
  type: ActionType.ADD_ALERT,
  payload: { alert, showOverlay },
});

export const removeAlert = (id: string): Action => ({
  type: ActionType.REMOVE_ALERT,
  payload: { id },
});

export const hideOverlay = (): Action => ({
  type: ActionType.HIDE_OVERLAY,
  payload: null,
});
