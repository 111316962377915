
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AvailabilityItem": [
      "CustomAvailabilityItem",
      "DefaultAvailabilityItem"
    ],
    "Connection": [
      "AppointmentConnection",
      "InvoiceConnection",
      "JobConnection",
      "NotificationConnection",
      "QuoteConnection",
      "RecordConnection",
      "TradespersonConnection"
    ],
    "Edge": [
      "AppointmentEdge",
      "InvoiceEdge",
      "JobEdge",
      "NotificationEdge",
      "QuoteEdge",
      "RecordEdge",
      "TradespersonEdge"
    ],
    "FieldDef": [
      "Field",
      "Group"
    ],
    "Node": [
      "Address",
      "Appointment",
      "BookingSlot",
      "Certificate",
      "Contact",
      "Customer",
      "CustomerAddress",
      "DefaultAvailability",
      "Dispute",
      "Invoice",
      "Job",
      "JobContact",
      "JobSheet",
      "JobSheetDefinition",
      "JobV2",
      "KantanRecord",
      "LineItem",
      "Notification",
      "NotificationSettings",
      "Organisation",
      "Quote",
      "RecordDefinition",
      "Tradesperson",
      "TradespersonCounter",
      "TradespersonPartner",
      "UnavailableDateRange"
    ]
  }
};
      export default result;
    