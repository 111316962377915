import { useEffectOnce } from 'kantan-utils';
import { DateTime } from 'luxon';
import * as React from 'react';

import { Notification } from 'src/apollo/__generated__/types';
import { useAuth } from 'src/apollo/auth/AuthProvider';
import {
  checkForNewNotifications,
  setLastSeenNotifications,
} from 'src/utils/notifications';
import { getItem } from 'src/utils/storage';

import { useNotifications } from './hooks/useNotifications';

type NotificationContextValue = {
  notifications?: Notification[];
  lastSeenFrom?: Date;
};

const NotificationContext = React.createContext<NotificationContextValue>({
  notifications: [],
});

type NotificationProviderProps = React.PropsWithChildren<{}>;

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const { isAuthReady, isAuthenticated } = useAuth();
  const { notificationsQuery } = useNotifications(
    {
      skip: !isAuthenticated || !isAuthReady,
    },
    () => {
      if (!!notificationsQuery?.data) {
        checkForNewNotifications(notificationsQuery?.data);
      }
      notificationsQuery?.data;
    },
  );

  useEffectOnce(() => {
    if (!getItem('notificationsLastSeen')) {
      setLastSeenNotifications(DateTime.now().minus({ days: 30 }));
    }
  });

  if (!isAuthReady && !isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <NotificationContext.Provider
      value={
        {
          notifications: notificationsQuery?.data,
        } ?? {}
      }
    >
      {children}
    </NotificationContext.Provider>
  );
};
