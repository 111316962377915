const getDataLayer = () => (window as any).dataLayer;

export const addDataLayerObject = (key: string, value: any) => {
  const dataLayer = getDataLayer();

  if (!dataLayer) return;

  const index = dataLayer.findIndex((o: any) => Object.keys(o).includes(key));

  if (index > -1) {
    dataLayer[index] = { [key]: value };
  } else {
    dataLayer.push({ [key]: value });
  }
};
