/* eslint-disable max-lines */
import '../styles/globals.css';
import '../styles/smartbanner.css';

import { datadogRum } from '@datadog/browser-rum-slim';
import { ConfigCatProvider, PollingMode } from 'configcat-react';
import { EventRegisterProvider } from 'kantan-utils';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { useEffect } from 'react';

import { AuthProvider } from 'src/apollo/auth/AuthProvider';
import { AuthorizationProvider } from 'src/apollo/authorization/AuthorizationProvider';
import { ApolloProvider } from 'src/apollo/components';
import { AlertProvider } from 'src/components/Alert/AlertProvider';
import { ModalProvider } from 'src/components/Modal';
import { NotificationProvider } from 'src/components/Notifications/NotificationProvider';
import { SmartBanner } from 'src/components/SmartBanner';
import { TrackPage } from 'src/utils/TrackPage';

import { NextPageWithLayout } from '../utils';

type NextPageWithLayoutApp = AppProps & {
  Component: NextPageWithLayout;
};

function KantanWebApp({ Component, pageProps }: NextPageWithLayoutApp) {
  const getLayout = Component.getLayout || ((page) => page);
  useEffect(() => {
    // Datadog RUM
    if (
      typeof window !== 'undefined' &&
      process.env.NODE_ENV === 'production'
    ) {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID ?? '',
        clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN ?? '',
        site: 'datadoghq.eu',
        service: 'kantan-web',
        env: process.env.NEXT_PUBLIC_ENVIRONMENT,
        sampleRate: 100,
        sessionReplaySampleRate: 0,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingOrigins: [
          process.env.NEXT_PUBLIC_KANTAN_GATEWAY_URI ?? '',
          process.env.NEXT_PUBLIC_MARKETPLACE_URI ?? '',
        ],
      });
    }
  }, []);

  return (
    <>
      {/* GTM Data Layer */}
      <Script id="data-layer">{`window.dataLayer = window.dataLayer || [];`}</Script>
      {/*Google Tag Manager*/}
      <Script id="google-tag-manager" strategy="afterInteractive">{`
        (function(w,d,s,l,i){var webview=window.localStorage.getItem('WEBVIEW');if(webview)return;
        var isImpersonating = 
        !!window.localStorage.getItem('BAK_ACCESS_TOKEN') || 
        !!window.localStorage.getItem('BAK_REFRESH_TOKEN') || 
        !!window.sessionStorage.getItem('BAK_REFRESH_TOKEN') ||
        !!window.sessionStorage.getItem('BAK_ACCESS_TOKEN');
        if (isImpersonating) return;
        w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_TAG_KEY}');
      `}</Script>
      {/* Segment script */}
      <Script id="segment-base" strategy="afterInteractive">
        {`!function () {
          var analytics = window.analytics = window.analytics || [];
          if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
            analytics.invoked = !0;
            analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
            analytics.factory = function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                t.unshift(e);
                analytics.push(t);
                return analytics
              }
            };
            for (var e = 0; e < analytics.methods.length; e++) {
              var key = analytics.methods[e];
              analytics[key] = analytics.factory(key)
            }
            analytics.load = function (key, e) {
              var t = document.createElement("script");
              t.type = "text/javascript";
              t.async = !0;
              t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
              var n = document.getElementsByTagName("script")[0];
              n.parentNode.insertBefore(t, n);
              analytics._loadOptions = e
            };
            analytics._writeKey = "${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}";
            ;analytics.SNIPPET_VERSION = "4.15.3";
            analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}");
            analytics.page();
          }
        }();`}
      </Script>
      <SmartBanner
        title={'Kantan'}
        daysHidden={15}
        daysReminder={30}
        button={'View'}
      />
      <ModalProvider>
        <AlertProvider>
          <EventRegisterProvider>
            <ConfigCatProvider
              sdkKey={process.env.NEXT_PUBLIC_CONFIGCAT_SDK_KEY || 'API_KEY'}
              pollingMode={PollingMode.LazyLoad}
              options={{
                cacheTimeToLiveSeconds: 5 * 60,
              }}
            >
              <AuthProvider>
                <AuthorizationProvider>
                  <ApolloProvider>
                    <NotificationProvider>
                      <TrackPage />
                      {getLayout(<Component {...pageProps} />)}
                    </NotificationProvider>
                  </ApolloProvider>
                </AuthorizationProvider>
              </AuthProvider>
            </ConfigCatProvider>
          </EventRegisterProvider>
        </AlertProvider>
      </ModalProvider>
    </>
  );
}

export default KantanWebApp;
