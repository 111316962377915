import { CTA, CTAProps } from '../CTA/CTA';
import { Icon, IconName } from '../Icon';
import styles from './modal.module.css';
export interface ConfirmationModalProps {
  icon: IconName;
  iconFill?: string;
  title: string;
  subTitle?: string;
  iconSize?: number;
  text?: string;
  secondaryCTA?: CTAProps;
  primaryCTA?: CTAProps;
  onDismiss?: () => void;
}

export const ConfirmationModal = ({
  icon,
  iconFill,
  title,
  subTitle,
  iconSize = 14,
  text = '',
  secondaryCTA,
  primaryCTA,
  onDismiss = () => {},
}: ConfirmationModalProps) => {
  const subTexts = text.split('\n');
  return (
    <div className={styles.small} data-testid="confirmation-modal">
      <div className="flex">
        <button
          onClick={onDismiss}
          className={styles.dismissButton}
          data-testid="confirmation-modal-button-dismiss-button"
        >
          <Icon name="cross" width={12} height={12} />
        </button>
        <span
          className={styles.iconBackground}
          data-testid="confirmation-modal-icon"
        >
          <Icon
            name={icon}
            pathFill={iconFill}
            width={iconSize}
            height={iconSize}
          />
        </span>
      </div>

      <span className={styles.title} data-testid="confirmation-modal-title">
        {title}
      </span>
      {subTitle && (
        <span
          className={styles.subTitle}
          data-testid="confirmation-modal-subtitle"
        >
          {subTitle}
        </span>
      )}
      {text && (
        <div
          className="flex flex-col text-center text-typography"
          data-testid="confirmation-modal-text"
        >
          {subTexts.map((subText, i) => (
            <span key={i}>{subText}</span>
          ))}
        </div>
      )}
      <div
        className="flex w-full gap-x-2"
        data-testid="confirmation-modal-ctas"
      >
        {secondaryCTA && <CTA type="secondary" {...secondaryCTA} />}
        {primaryCTA && <CTA type="primary" {...primaryCTA} />}
      </div>
    </div>
  );
};
