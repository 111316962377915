export type Permissions =
  | 'canEditJobsheet'
  | 'canEditSubmittedJobsheet'
  | 'canViewAllPartners';
export type PermissionValues = Record<Permissions, boolean>;

export const DefaultStandardPermissions: PermissionValues = {
  canEditJobsheet: true,
  canEditSubmittedJobsheet: false,
  canViewAllPartners: true,
};

export const DefaultImpersonationPermissions: PermissionValues = {
  canEditJobsheet: false,
  canEditSubmittedJobsheet: false,
  canViewAllPartners: false,
};
