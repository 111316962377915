import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import * as Types from '../../__generated__/types';
const defaultOptions =  {}
export const ContactFragment = gql`
    fragment ContactFragment on Contact {
  id
  fullName
  companyName
  emailAddress
  customerId
  phoneNumberList {
    id
    phoneNumber
    label
  }
}
    `;
export const InvoiceCardInvoiceFragment = gql`
    fragment InvoiceCardInvoiceFragment on Invoice {
  id
  amount
  status
  contact {
    ...ContactFragment
  }
  tags {
    name
    style
    label
  }
  lineItems {
    id
    description
    amount
    quantity
    taxPercentage
    total
  }
  jobId
  paidAt
  sentAt
  dueDate
  jobReferenceId
  invoicedAt
  invoiceUri
  disputedAt
  isOverdue
  isPrivateInvoice
  total
  hasDiscount
  discountAmount
  discountType
  additionalComment
  contactAddress {
    id
    postcode
    town
  }
  jobAddress {
    id
    postcode
    town
  }
  partnerId
  tradespersonId
  companyId
}
    ${ContactFragment}`;
export const JobDetailsThatCanChangeFragment = gql`
    fragment JobDetailsThatCanChangeFragment on Job {
  tags {
    name
    label
    style
    displayName
  }
  additionalInformation {
    key
    label
    value
    displayOnJobCard
    displayOnJobSheet
  }
}
    `;
export const JobDetailsFragment = gql`
    fragment JobDetailsFragment on Job {
  id
  notes
  closingNotes
  closedAt
  createdAt
  updatedAt
  ownershipType
  startedAt
  startedTravelAt
  completedAt
  completedBy
  isPrivateJob
  partner {
    id
  }
  duration
  startTime
  startLabel
  useConsolidatedJobsheet
  brand {
    name
  }
  address {
    id
    line1
    line2
    town
    postcode
    referenceId
  }
  contactList {
    id
    customerId
    tags
    fullName
    contactType
    emailAddress
    phoneNumberList {
      id
      phoneNumber
      originallyEnteredAs
      label
    }
  }
  type {
    name
    group
  }
  product {
    id
    showLabourHours
  }
  lastAllocatedAt
  isAutoAllocated
  companyId
  tradespersonId
  ...JobDetailsThatCanChangeFragment
}
    ${JobDetailsThatCanChangeFragment}`;
export const JobSheetFragment = gql`
    fragment JobSheetFragment on JobSheet {
  id
  report
  submittedAt
  definition {
    id
    jobType
    minAppVersion
    definition {
      sections {
        name
        type
        omitIfNotRequired
        requires {
          rule
          args
        }
        effects {
          rule
          args
        }
        config
        title
        validationRules
        validators {
          name
          options
        }
        fields {
          ... on Group {
            title
            name
            type
            children
            summary {
              type
              title
            }
            validationRules
            requires {
              rule
              args
            }
            omitIfNotRequired
          }
          ... on Field {
            title
            subtitle
            name
            type
            summary {
              type
              title
            }
            reading {
              name
              unit
              validationRules
              requires {
                rule
                args
              }
            }
            unit
            validationRules
            omitIfNotRequired
            requires {
              rule
              args
            }
            config
            hidden
            placeholder
          }
        }
      }
      validationRules
    }
  }
}
    `;
export const CustomerQueryFragment = gql`
    fragment CustomerQueryFragment on Customer {
  id
  notes
  customerAddresses {
    id
    address {
      id
      line1
      line2
      town
      postcode
      country
    }
  }
  contact {
    ...ContactFragment
  }
}
    ${ContactFragment}`;
export const AppConfigurationDocument = gql`
    query AppConfiguration {
  configuration {
    id
    DAILY_SLOT_SHAPE {
      startTime
      endTime
      name
      queryStartTime
      queryEndTime
    }
    SLOT_RATING {
      recommendedThreshold
    }
    GQL_CONFIG {
      pollInterval
    }
    TERMS_AND_CONDITIONS {
      id
      url
    }
    SAVE_TIME_AUTOBOOKING_JOB_THRESHOLD
    MARKETING {
      id
      text
    }
    INTEGRATIONS {
      quickbooks {
        connected
      }
    }
  }
}
    `;

/**
 * __useAppConfiguration__
 *
 * To run a query within a React component, call `useAppConfiguration` and pass it any options that fit your needs.
 * When your component renders, `useAppConfiguration` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppConfiguration({
 *   variables: {
 *   },
 * });
 */
export function useAppConfiguration(baseOptions?: Apollo.QueryHookOptions<Types.AppConfiguration, Types.AppConfigurationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AppConfiguration, Types.AppConfigurationVariables>(AppConfigurationDocument, options);
      }
export function useAppConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AppConfiguration, Types.AppConfigurationVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AppConfiguration, Types.AppConfigurationVariables>(AppConfigurationDocument, options);
        }
export type AppConfigurationHookResult = ReturnType<typeof useAppConfiguration>;
export type AppConfigurationLazyQueryHookResult = ReturnType<typeof useAppConfigurationLazyQuery>;
export type AppConfigurationQueryResult = Apollo.QueryResult<Types.AppConfiguration, Types.AppConfigurationVariables>;
export const AppointmentSlotWithRatingsDocument = gql`
    query AppointmentSlotWithRatings($appointmentId: ID!, $slot: InputSlotShape!) {
  appointmentSlotRatings(appointmentId: $appointmentId, slot: $slot) {
    startDatetime
    endDatetime
    slotName
    rating {
      rating
      score
    }
  }
}
    `;

/**
 * __useAppointmentSlotWithRatings__
 *
 * To run a query within a React component, call `useAppointmentSlotWithRatings` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentSlotWithRatings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentSlotWithRatings({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      slot: // value for 'slot'
 *   },
 * });
 */
export function useAppointmentSlotWithRatings(baseOptions: Apollo.QueryHookOptions<Types.AppointmentSlotWithRatings, Types.AppointmentSlotWithRatingsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AppointmentSlotWithRatings, Types.AppointmentSlotWithRatingsVariables>(AppointmentSlotWithRatingsDocument, options);
      }
export function useAppointmentSlotWithRatingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AppointmentSlotWithRatings, Types.AppointmentSlotWithRatingsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AppointmentSlotWithRatings, Types.AppointmentSlotWithRatingsVariables>(AppointmentSlotWithRatingsDocument, options);
        }
export type AppointmentSlotWithRatingsHookResult = ReturnType<typeof useAppointmentSlotWithRatings>;
export type AppointmentSlotWithRatingsLazyQueryHookResult = ReturnType<typeof useAppointmentSlotWithRatingsLazyQuery>;
export type AppointmentSlotWithRatingsQueryResult = Apollo.QueryResult<Types.AppointmentSlotWithRatings, Types.AppointmentSlotWithRatingsVariables>;
export const CalendarJobsDocument = gql`
    query CalendarJobs($after: DateTime, $before: DateTime, $tradespersonIds: [ID]) {
  tradesperson {
    id
    jobs(
      filter: {booked: true, tradespersonIds: $tradespersonIds, fulfillmentWindowStartAfter: $after, fulfillmentWindowEndBefore: $before}
    ) {
      edges {
        node {
          id
          startedAt
          completedAt
          fulfillmentWindow {
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCalendarJobs__
 *
 * To run a query within a React component, call `useCalendarJobs` and pass it any options that fit your needs.
 * When your component renders, `useCalendarJobs` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarJobs({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      tradespersonIds: // value for 'tradespersonIds'
 *   },
 * });
 */
export function useCalendarJobs(baseOptions?: Apollo.QueryHookOptions<Types.CalendarJobs, Types.CalendarJobsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CalendarJobs, Types.CalendarJobsVariables>(CalendarJobsDocument, options);
      }
export function useCalendarJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CalendarJobs, Types.CalendarJobsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CalendarJobs, Types.CalendarJobsVariables>(CalendarJobsDocument, options);
        }
export type CalendarJobsHookResult = ReturnType<typeof useCalendarJobs>;
export type CalendarJobsLazyQueryHookResult = ReturnType<typeof useCalendarJobsLazyQuery>;
export type CalendarJobsQueryResult = Apollo.QueryResult<Types.CalendarJobs, Types.CalendarJobsVariables>;
export const GetDocumentsDocument = gql`
    query GetDocuments($filters: DocumentFilter!) {
  tradesperson {
    id
    documents(filter: $filters) {
      count
      results {
        type
        fileName
        uri
        preSignedUri
        uploadDate
        jobReferenceId
      }
    }
  }
}
    `;

/**
 * __useGetDocuments__
 *
 * To run a query within a React component, call `useGetDocuments` and pass it any options that fit your needs.
 * When your component renders, `useGetDocuments` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocuments({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDocuments(baseOptions: Apollo.QueryHookOptions<Types.GetDocuments, Types.GetDocumentsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocuments, Types.GetDocumentsVariables>(GetDocumentsDocument, options);
      }
export function useGetDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocuments, Types.GetDocumentsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocuments, Types.GetDocumentsVariables>(GetDocumentsDocument, options);
        }
export type GetDocumentsHookResult = ReturnType<typeof useGetDocuments>;
export type GetDocumentsLazyQueryHookResult = ReturnType<typeof useGetDocumentsLazyQuery>;
export type GetDocumentsQueryResult = Apollo.QueryResult<Types.GetDocuments, Types.GetDocumentsVariables>;
export const GetInvoiceStatsDocument = gql`
    query GetInvoiceStats($filter: InvoiceStatsFilter) {
  tradesperson {
    id
    invoiceStats(filter: $filter) {
      totalAmounts {
        paid
        unpaid
        unsent
        overdue
        pending
        disputed
      }
      sent
      paid
      unpaid
      pending
      disputed
      unsent
      overdue
      active
    }
  }
}
    `;

/**
 * __useGetInvoiceStats__
 *
 * To run a query within a React component, call `useGetInvoiceStats` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceStats` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceStats({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInvoiceStats(baseOptions?: Apollo.QueryHookOptions<Types.GetInvoiceStats, Types.GetInvoiceStatsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInvoiceStats, Types.GetInvoiceStatsVariables>(GetInvoiceStatsDocument, options);
      }
export function useGetInvoiceStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvoiceStats, Types.GetInvoiceStatsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInvoiceStats, Types.GetInvoiceStatsVariables>(GetInvoiceStatsDocument, options);
        }
export type GetInvoiceStatsHookResult = ReturnType<typeof useGetInvoiceStats>;
export type GetInvoiceStatsLazyQueryHookResult = ReturnType<typeof useGetInvoiceStatsLazyQuery>;
export type GetInvoiceStatsQueryResult = Apollo.QueryResult<Types.GetInvoiceStats, Types.GetInvoiceStatsVariables>;
export const GetInvoicesV2Document = gql`
    query GetInvoicesV2($orderBy: InvoiceOrderBy, $groupBy: InvoiceGroupBy, $filters: InvoiceFilter, $paging: PageNumberPaging!) {
  tradesperson {
    id
    invoicesV2(
      orderBy: $orderBy
      groupBy: $groupBy
      filter: $filters
      paging: $paging
    ) {
      count
      results {
        ...InvoiceCardInvoiceFragment
      }
    }
  }
}
    ${InvoiceCardInvoiceFragment}`;

/**
 * __useGetInvoicesV2__
 *
 * To run a query within a React component, call `useGetInvoicesV2` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesV2` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesV2({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      groupBy: // value for 'groupBy'
 *      filters: // value for 'filters'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetInvoicesV2(baseOptions: Apollo.QueryHookOptions<Types.GetInvoicesV2, Types.GetInvoicesV2Variables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInvoicesV2, Types.GetInvoicesV2Variables>(GetInvoicesV2Document, options);
      }
export function useGetInvoicesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvoicesV2, Types.GetInvoicesV2Variables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInvoicesV2, Types.GetInvoicesV2Variables>(GetInvoicesV2Document, options);
        }
export type GetInvoicesV2HookResult = ReturnType<typeof useGetInvoicesV2>;
export type GetInvoicesV2LazyQueryHookResult = ReturnType<typeof useGetInvoicesV2LazyQuery>;
export type GetInvoicesV2QueryResult = Apollo.QueryResult<Types.GetInvoicesV2, Types.GetInvoicesV2Variables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($filter: NotificationsFilter, $paging: Paging, $apolloQueryKey: String) {
  tradesperson {
    id
    notifications(filter: $filter, paging: $paging, apolloQueryKey: $apolloQueryKey) {
      edges {
        node {
          id
          createdAt
          context
        }
      }
      pageInfo {
        hasNextPage
        nextCursor
      }
    }
  }
}
    `;

/**
 * __useGetNotifications__
 *
 * To run a query within a React component, call `useGetNotifications` and pass it any options that fit your needs.
 * When your component renders, `useGetNotifications` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotifications({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      apolloQueryKey: // value for 'apolloQueryKey'
 *   },
 * });
 */
export function useGetNotifications(baseOptions?: Apollo.QueryHookOptions<Types.GetNotifications, Types.GetNotificationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNotifications, Types.GetNotificationsVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNotifications, Types.GetNotificationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNotifications, Types.GetNotificationsVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsHookResult = ReturnType<typeof useGetNotifications>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<Types.GetNotifications, Types.GetNotificationsVariables>;
export const JobDetailsDocument = gql`
    query JobDetails($id: ID!) {
  job(id: $id) {
    partner {
      id
    }
    jobSheet {
      ...JobSheetFragment
    }
    invoice {
      id
      amount
      paidAt
      invoicedAt
      disputedAt
      jobReferenceId
      invoiceUri
      disputes {
        id
        type
        message
        acceptedAt
        resolvedAt
        isAcceptable
      }
    }
    invoices {
      ...InvoiceCardInvoiceFragment
    }
    appointmentId
    bookedBy
    bookedAt
    useConsolidatedJobsheet
    contactList {
      emailAddress
      customerId
    }
    fulfillmentWindow {
      startDateTime
      endDateTime
    }
    startTime
    startLabel
    metadata {
      costsPreApproval {
        totalCost
        validCost
        boilerMake
      }
    }
    ...JobDetailsFragment
    preferredSlots {
      id
      startDatetime
      endDatetime
      slotName
    }
    lastAllocatedAt
    isAutoAllocated
  }
  appointment: latestAppointment(jobId: $id) {
    id
    jobId
    windowStart
    windowEnd
    canBeAutobooked
    setForAutobooking
    setForAutobookingAt
  }
}
    ${JobSheetFragment}
${InvoiceCardInvoiceFragment}
${JobDetailsFragment}`;

/**
 * __useJobDetails__
 *
 * To run a query within a React component, call `useJobDetails` and pass it any options that fit your needs.
 * When your component renders, `useJobDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobDetails(baseOptions: Apollo.QueryHookOptions<Types.JobDetails, Types.JobDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobDetails, Types.JobDetailsVariables>(JobDetailsDocument, options);
      }
export function useJobDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobDetails, Types.JobDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobDetails, Types.JobDetailsVariables>(JobDetailsDocument, options);
        }
export type JobDetailsHookResult = ReturnType<typeof useJobDetails>;
export type JobDetailsLazyQueryHookResult = ReturnType<typeof useJobDetailsLazyQuery>;
export type JobDetailsQueryResult = Apollo.QueryResult<Types.JobDetails, Types.JobDetailsVariables>;
export const JobHistoriesDocument = gql`
    query JobHistories($id: ID!) {
  jobHistory(id: $id) {
    appointmentDate
    faultFound
    actionTaken
    partsReplaced
    jobTypeGroup
    tags {
      name
      label
      style
      displayName
    }
  }
}
    `;

/**
 * __useJobHistories__
 *
 * To run a query within a React component, call `useJobHistories` and pass it any options that fit your needs.
 * When your component renders, `useJobHistories` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobHistories({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobHistories(baseOptions: Apollo.QueryHookOptions<Types.JobHistories, Types.JobHistoriesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobHistories, Types.JobHistoriesVariables>(JobHistoriesDocument, options);
      }
export function useJobHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobHistories, Types.JobHistoriesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobHistories, Types.JobHistoriesVariables>(JobHistoriesDocument, options);
        }
export type JobHistoriesHookResult = ReturnType<typeof useJobHistories>;
export type JobHistoriesLazyQueryHookResult = ReturnType<typeof useJobHistoriesLazyQuery>;
export type JobHistoriesQueryResult = Apollo.QueryResult<Types.JobHistories, Types.JobHistoriesVariables>;
export const JobInvoicingDetailsDocument = gql`
    query JobInvoicingDetails($id: ID!) {
  job(id: $id) {
    id
    invoicingDetails {
      rates {
        id
        type
        unit
        amount
        currency
      }
      partMarkup {
        id
        rate
      }
      vat {
        id
        rate
        registered
      }
    }
    invoicingRateId
  }
}
    `;

/**
 * __useJobInvoicingDetails__
 *
 * To run a query within a React component, call `useJobInvoicingDetails` and pass it any options that fit your needs.
 * When your component renders, `useJobInvoicingDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobInvoicingDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobInvoicingDetails(baseOptions: Apollo.QueryHookOptions<Types.JobInvoicingDetails, Types.JobInvoicingDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobInvoicingDetails, Types.JobInvoicingDetailsVariables>(JobInvoicingDetailsDocument, options);
      }
export function useJobInvoicingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobInvoicingDetails, Types.JobInvoicingDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobInvoicingDetails, Types.JobInvoicingDetailsVariables>(JobInvoicingDetailsDocument, options);
        }
export type JobInvoicingDetailsHookResult = ReturnType<typeof useJobInvoicingDetails>;
export type JobInvoicingDetailsLazyQueryHookResult = ReturnType<typeof useJobInvoicingDetailsLazyQuery>;
export type JobInvoicingDetailsQueryResult = Apollo.QueryResult<Types.JobInvoicingDetails, Types.JobInvoicingDetailsVariables>;
export const JobPollDetailsDocument = gql`
    query JobPollDetails($id: ID!) {
  job(id: $id) {
    id
    ...JobDetailsThatCanChangeFragment
  }
}
    ${JobDetailsThatCanChangeFragment}`;

/**
 * __useJobPollDetails__
 *
 * To run a query within a React component, call `useJobPollDetails` and pass it any options that fit your needs.
 * When your component renders, `useJobPollDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPollDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobPollDetails(baseOptions: Apollo.QueryHookOptions<Types.JobPollDetails, Types.JobPollDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobPollDetails, Types.JobPollDetailsVariables>(JobPollDetailsDocument, options);
      }
export function useJobPollDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobPollDetails, Types.JobPollDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobPollDetails, Types.JobPollDetailsVariables>(JobPollDetailsDocument, options);
        }
export type JobPollDetailsHookResult = ReturnType<typeof useJobPollDetails>;
export type JobPollDetailsLazyQueryHookResult = ReturnType<typeof useJobPollDetailsLazyQuery>;
export type JobPollDetailsQueryResult = Apollo.QueryResult<Types.JobPollDetails, Types.JobPollDetailsVariables>;
export const JobSheetDetailsDocument = gql`
    query JobSheetDetails($id: ID!) {
  jobSheet(id: $id) {
    ...JobSheetFragment
  }
}
    ${JobSheetFragment}`;

/**
 * __useJobSheetDetails__
 *
 * To run a query within a React component, call `useJobSheetDetails` and pass it any options that fit your needs.
 * When your component renders, `useJobSheetDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSheetDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobSheetDetails(baseOptions: Apollo.QueryHookOptions<Types.JobSheetDetails, Types.JobSheetDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobSheetDetails, Types.JobSheetDetailsVariables>(JobSheetDetailsDocument, options);
      }
export function useJobSheetDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobSheetDetails, Types.JobSheetDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobSheetDetails, Types.JobSheetDetailsVariables>(JobSheetDetailsDocument, options);
        }
export type JobSheetDetailsHookResult = ReturnType<typeof useJobSheetDetails>;
export type JobSheetDetailsLazyQueryHookResult = ReturnType<typeof useJobSheetDetailsLazyQuery>;
export type JobSheetDetailsQueryResult = Apollo.QueryResult<Types.JobSheetDetails, Types.JobSheetDetailsVariables>;
export const PaginatedJobsDocument = gql`
    query PaginatedJobs($filter: JobFilter, $orderBy: JobOrderBy, $paging: PageNumberPaging!, $repairsFirst: Boolean) {
  tradesperson {
    id
    paginatedJobs(
      filter: $filter
      orderBy: $orderBy
      paging: $paging
      repairsFirst: $repairsFirst
    ) {
      count
      results {
        id
        appointmentId
      }
    }
  }
}
    `;

/**
 * __usePaginatedJobs__
 *
 * To run a query within a React component, call `usePaginatedJobs` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedJobs` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedJobs({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      paging: // value for 'paging'
 *      repairsFirst: // value for 'repairsFirst'
 *   },
 * });
 */
export function usePaginatedJobs(baseOptions: Apollo.QueryHookOptions<Types.PaginatedJobs, Types.PaginatedJobsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PaginatedJobs, Types.PaginatedJobsVariables>(PaginatedJobsDocument, options);
      }
export function usePaginatedJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PaginatedJobs, Types.PaginatedJobsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PaginatedJobs, Types.PaginatedJobsVariables>(PaginatedJobsDocument, options);
        }
export type PaginatedJobsHookResult = ReturnType<typeof usePaginatedJobs>;
export type PaginatedJobsLazyQueryHookResult = ReturnType<typeof usePaginatedJobsLazyQuery>;
export type PaginatedJobsQueryResult = Apollo.QueryResult<Types.PaginatedJobs, Types.PaginatedJobsVariables>;
export const PartnersForTradespersonCompanyDocument = gql`
    query PartnersForTradespersonCompany {
  tradesperson {
    id
    partnersForCompanies {
      id
      slug
    }
  }
}
    `;

/**
 * __usePartnersForTradespersonCompany__
 *
 * To run a query within a React component, call `usePartnersForTradespersonCompany` and pass it any options that fit your needs.
 * When your component renders, `usePartnersForTradespersonCompany` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnersForTradespersonCompany({
 *   variables: {
 *   },
 * });
 */
export function usePartnersForTradespersonCompany(baseOptions?: Apollo.QueryHookOptions<Types.PartnersForTradespersonCompany, Types.PartnersForTradespersonCompanyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PartnersForTradespersonCompany, Types.PartnersForTradespersonCompanyVariables>(PartnersForTradespersonCompanyDocument, options);
      }
export function usePartnersForTradespersonCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PartnersForTradespersonCompany, Types.PartnersForTradespersonCompanyVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PartnersForTradespersonCompany, Types.PartnersForTradespersonCompanyVariables>(PartnersForTradespersonCompanyDocument, options);
        }
export type PartnersForTradespersonCompanyHookResult = ReturnType<typeof usePartnersForTradespersonCompany>;
export type PartnersForTradespersonCompanyLazyQueryHookResult = ReturnType<typeof usePartnersForTradespersonCompanyLazyQuery>;
export type PartnersForTradespersonCompanyQueryResult = Apollo.QueryResult<Types.PartnersForTradespersonCompany, Types.PartnersForTradespersonCompanyVariables>;
export const TestJobDetailsDocument = gql`
    query TestJobDetails($id: ID!) {
  job(id: $id) {
    id
    completedAt
    closedAt
    fulfillmentWindow {
      startDateTime
      endDateTime
    }
    tags {
      name
      label
      style
      displayName
    }
    type {
      name
      group
    }
    brand {
      name
    }
    jobSheet {
      ...JobSheetFragment
    }
    appointmentId
    useConsolidatedJobsheet
    companyId
    tradespersonId
  }
  appointment: latestAppointment(jobId: $id) {
    id
    setForAutobooking
    setForAutobookingAt
  }
}
    ${JobSheetFragment}`;

/**
 * __useTestJobDetails__
 *
 * To run a query within a React component, call `useTestJobDetails` and pass it any options that fit your needs.
 * When your component renders, `useTestJobDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestJobDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestJobDetails(baseOptions: Apollo.QueryHookOptions<Types.TestJobDetails, Types.TestJobDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TestJobDetails, Types.TestJobDetailsVariables>(TestJobDetailsDocument, options);
      }
export function useTestJobDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TestJobDetails, Types.TestJobDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TestJobDetails, Types.TestJobDetailsVariables>(TestJobDetailsDocument, options);
        }
export type TestJobDetailsHookResult = ReturnType<typeof useTestJobDetails>;
export type TestJobDetailsLazyQueryHookResult = ReturnType<typeof useTestJobDetailsLazyQuery>;
export type TestJobDetailsQueryResult = Apollo.QueryResult<Types.TestJobDetails, Types.TestJobDetailsVariables>;
export const TradespersonAccountDeletionProgressDocument = gql`
    query TradespersonAccountDeletionProgress {
  tradesperson {
    id
    isDeletionInProgress
    hasAcceptedLatestTerms
  }
}
    `;

/**
 * __useTradespersonAccountDeletionProgress__
 *
 * To run a query within a React component, call `useTradespersonAccountDeletionProgress` and pass it any options that fit your needs.
 * When your component renders, `useTradespersonAccountDeletionProgress` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradespersonAccountDeletionProgress({
 *   variables: {
 *   },
 * });
 */
export function useTradespersonAccountDeletionProgress(baseOptions?: Apollo.QueryHookOptions<Types.TradespersonAccountDeletionProgress, Types.TradespersonAccountDeletionProgressVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradespersonAccountDeletionProgress, Types.TradespersonAccountDeletionProgressVariables>(TradespersonAccountDeletionProgressDocument, options);
      }
export function useTradespersonAccountDeletionProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradespersonAccountDeletionProgress, Types.TradespersonAccountDeletionProgressVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradespersonAccountDeletionProgress, Types.TradespersonAccountDeletionProgressVariables>(TradespersonAccountDeletionProgressDocument, options);
        }
export type TradespersonAccountDeletionProgressHookResult = ReturnType<typeof useTradespersonAccountDeletionProgress>;
export type TradespersonAccountDeletionProgressLazyQueryHookResult = ReturnType<typeof useTradespersonAccountDeletionProgressLazyQuery>;
export type TradespersonAccountDeletionProgressQueryResult = Apollo.QueryResult<Types.TradespersonAccountDeletionProgress, Types.TradespersonAccountDeletionProgressVariables>;
export const TradespersonCustomerDocument = gql`
    query TradespersonCustomer($id: ID!) {
  tradesperson {
    id
    customer(id: $id) {
      ...CustomerQueryFragment
    }
  }
}
    ${CustomerQueryFragment}`;

/**
 * __useTradespersonCustomer__
 *
 * To run a query within a React component, call `useTradespersonCustomer` and pass it any options that fit your needs.
 * When your component renders, `useTradespersonCustomer` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradespersonCustomer({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTradespersonCustomer(baseOptions: Apollo.QueryHookOptions<Types.TradespersonCustomer, Types.TradespersonCustomerVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradespersonCustomer, Types.TradespersonCustomerVariables>(TradespersonCustomerDocument, options);
      }
export function useTradespersonCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradespersonCustomer, Types.TradespersonCustomerVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradespersonCustomer, Types.TradespersonCustomerVariables>(TradespersonCustomerDocument, options);
        }
export type TradespersonCustomerHookResult = ReturnType<typeof useTradespersonCustomer>;
export type TradespersonCustomerLazyQueryHookResult = ReturnType<typeof useTradespersonCustomerLazyQuery>;
export type TradespersonCustomerQueryResult = Apollo.QueryResult<Types.TradespersonCustomer, Types.TradespersonCustomerVariables>;
export const TradespersonCustomersDocument = gql`
    query TradespersonCustomers {
  tradesperson {
    id
    customers {
      ...CustomerQueryFragment
    }
  }
}
    ${CustomerQueryFragment}`;

/**
 * __useTradespersonCustomers__
 *
 * To run a query within a React component, call `useTradespersonCustomers` and pass it any options that fit your needs.
 * When your component renders, `useTradespersonCustomers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradespersonCustomers({
 *   variables: {
 *   },
 * });
 */
export function useTradespersonCustomers(baseOptions?: Apollo.QueryHookOptions<Types.TradespersonCustomers, Types.TradespersonCustomersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradespersonCustomers, Types.TradespersonCustomersVariables>(TradespersonCustomersDocument, options);
      }
export function useTradespersonCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradespersonCustomers, Types.TradespersonCustomersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradespersonCustomers, Types.TradespersonCustomersVariables>(TradespersonCustomersDocument, options);
        }
export type TradespersonCustomersHookResult = ReturnType<typeof useTradespersonCustomers>;
export type TradespersonCustomersLazyQueryHookResult = ReturnType<typeof useTradespersonCustomersLazyQuery>;
export type TradespersonCustomersQueryResult = Apollo.QueryResult<Types.TradespersonCustomers, Types.TradespersonCustomersVariables>;
export const TradespersonDetailsDocument = gql`
    query TradespersonDetails {
  tradesperson {
    id
    createdAt
    companyName
    fullName
    maxJobsPerSlot
    maxTravelTime
    postalOutcodesCovered
    bankAccountNumber
    bankAccountName
    bankSortCode
    bankInstitutionName
    taxEnabled
    gsrNumber
    allTrades
    taxPercentage
    taxRegistrationNumber
    emailAddress
    hasAcceptedLatestTerms
    phoneNumber {
      id
      label
      phoneNumber
    }
    counters {
      id
      nextNumber
      counterName
    }
    companyLogoUrl
    signatureUrl
    consent {
      id
      consentId
      consentModelClass
    }
    calendarUrl
    calendarUrlShort
    pushNotificationTokens {
      registrationId
    }
  }
}
    `;

/**
 * __useTradespersonDetails__
 *
 * To run a query within a React component, call `useTradespersonDetails` and pass it any options that fit your needs.
 * When your component renders, `useTradespersonDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradespersonDetails({
 *   variables: {
 *   },
 * });
 */
export function useTradespersonDetails(baseOptions?: Apollo.QueryHookOptions<Types.TradespersonDetails, Types.TradespersonDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradespersonDetails, Types.TradespersonDetailsVariables>(TradespersonDetailsDocument, options);
      }
export function useTradespersonDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradespersonDetails, Types.TradespersonDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradespersonDetails, Types.TradespersonDetailsVariables>(TradespersonDetailsDocument, options);
        }
export type TradespersonDetailsHookResult = ReturnType<typeof useTradespersonDetails>;
export type TradespersonDetailsLazyQueryHookResult = ReturnType<typeof useTradespersonDetailsLazyQuery>;
export type TradespersonDetailsQueryResult = Apollo.QueryResult<Types.TradespersonDetails, Types.TradespersonDetailsVariables>;
export const UnavailableDatesDocument = gql`
    query UnavailableDates($after: String!) {
  tradesperson {
    id
    partners {
      id
      unavailableDates(filter: {after: $after}) {
        id
        status
        createdAt
        startDate
        endDate
        updateAutobookAvailability
      }
    }
  }
}
    `;

/**
 * __useUnavailableDates__
 *
 * To run a query within a React component, call `useUnavailableDates` and pass it any options that fit your needs.
 * When your component renders, `useUnavailableDates` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnavailableDates({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUnavailableDates(baseOptions: Apollo.QueryHookOptions<Types.UnavailableDates, Types.UnavailableDatesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnavailableDates, Types.UnavailableDatesVariables>(UnavailableDatesDocument, options);
      }
export function useUnavailableDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnavailableDates, Types.UnavailableDatesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnavailableDates, Types.UnavailableDatesVariables>(UnavailableDatesDocument, options);
        }
export type UnavailableDatesHookResult = ReturnType<typeof useUnavailableDates>;
export type UnavailableDatesLazyQueryHookResult = ReturnType<typeof useUnavailableDatesLazyQuery>;
export type UnavailableDatesQueryResult = Apollo.QueryResult<Types.UnavailableDates, Types.UnavailableDatesVariables>;
export const UnbookedAppointmentDocument = gql`
    query UnbookedAppointment($jobId: ID!) {
  unbookedAppointment(jobId: $jobId) {
    id
    jobId
    showNotifyCustomerPromptOnManualBooking
  }
}
    `;

/**
 * __useUnbookedAppointment__
 *
 * To run a query within a React component, call `useUnbookedAppointment` and pass it any options that fit your needs.
 * When your component renders, `useUnbookedAppointment` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnbookedAppointment({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useUnbookedAppointment(baseOptions: Apollo.QueryHookOptions<Types.UnbookedAppointment, Types.UnbookedAppointmentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnbookedAppointment, Types.UnbookedAppointmentVariables>(UnbookedAppointmentDocument, options);
      }
export function useUnbookedAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnbookedAppointment, Types.UnbookedAppointmentVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnbookedAppointment, Types.UnbookedAppointmentVariables>(UnbookedAppointmentDocument, options);
        }
export type UnbookedAppointmentHookResult = ReturnType<typeof useUnbookedAppointment>;
export type UnbookedAppointmentLazyQueryHookResult = ReturnType<typeof useUnbookedAppointmentLazyQuery>;
export type UnbookedAppointmentQueryResult = Apollo.QueryResult<Types.UnbookedAppointment, Types.UnbookedAppointmentVariables>;
export const JobPartnerIdQueryDocument = gql`
    query JobPartnerIdQuery($jobId: ID!) {
  job(id: $jobId) {
    companyId
    partner {
      id
    }
  }
}
    `;

/**
 * __useJobPartnerIdQuery__
 *
 * To run a query within a React component, call `useJobPartnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPartnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPartnerIdQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobPartnerIdQuery(baseOptions: Apollo.QueryHookOptions<Types.JobPartnerIdQuery, Types.JobPartnerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobPartnerIdQuery, Types.JobPartnerIdQueryVariables>(JobPartnerIdQueryDocument, options);
      }
export function useJobPartnerIdQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobPartnerIdQuery, Types.JobPartnerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobPartnerIdQuery, Types.JobPartnerIdQueryVariables>(JobPartnerIdQueryDocument, options);
        }
export type JobPartnerIdQueryHookResult = ReturnType<typeof useJobPartnerIdQuery>;
export type JobPartnerIdQueryLazyQueryHookResult = ReturnType<typeof useJobPartnerIdQueryLazyQuery>;
export type JobPartnerIdQueryQueryResult = Apollo.QueryResult<Types.JobPartnerIdQuery, Types.JobPartnerIdQueryVariables>;
export const BookingPanelAppointmentsQueryDocument = gql`
    query BookingPanelAppointmentsQuery($filter: AppointmentFilter) {
  tradesperson {
    id
    appointments(filter: $filter) {
      edges {
        node {
          id
          jobType
          job {
            id
            companyId
            tradespersonId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBookingPanelAppointmentsQuery__
 *
 * To run a query within a React component, call `useBookingPanelAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingPanelAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingPanelAppointmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBookingPanelAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.BookingPanelAppointmentsQuery, Types.BookingPanelAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BookingPanelAppointmentsQuery, Types.BookingPanelAppointmentsQueryVariables>(BookingPanelAppointmentsQueryDocument, options);
      }
export function useBookingPanelAppointmentsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BookingPanelAppointmentsQuery, Types.BookingPanelAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BookingPanelAppointmentsQuery, Types.BookingPanelAppointmentsQueryVariables>(BookingPanelAppointmentsQueryDocument, options);
        }
export type BookingPanelAppointmentsQueryHookResult = ReturnType<typeof useBookingPanelAppointmentsQuery>;
export type BookingPanelAppointmentsQueryLazyQueryHookResult = ReturnType<typeof useBookingPanelAppointmentsQueryLazyQuery>;
export type BookingPanelAppointmentsQueryQueryResult = Apollo.QueryResult<Types.BookingPanelAppointmentsQuery, Types.BookingPanelAppointmentsQueryVariables>;
export const BookingPanelCardQueryDocument = gql`
    query BookingPanelCardQuery($jobId: ID!) {
  job(id: $jobId) {
    id
    contactList {
      id
      fullName
      phoneNumberList {
        id
        label
        phoneNumber
      }
    }
    isPrivateJob
    brand {
      name
    }
    address {
      id
      line1
      line2
      postcode
      town
    }
    tags {
      label
      name
      displayName
    }
    type {
      name
      group
    }
    useConsolidatedJobsheet
  }
}
    `;

/**
 * __useBookingPanelCardQuery__
 *
 * To run a query within a React component, call `useBookingPanelCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingPanelCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingPanelCardQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useBookingPanelCardQuery(baseOptions: Apollo.QueryHookOptions<Types.BookingPanelCardQuery, Types.BookingPanelCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BookingPanelCardQuery, Types.BookingPanelCardQueryVariables>(BookingPanelCardQueryDocument, options);
      }
export function useBookingPanelCardQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BookingPanelCardQuery, Types.BookingPanelCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BookingPanelCardQuery, Types.BookingPanelCardQueryVariables>(BookingPanelCardQueryDocument, options);
        }
export type BookingPanelCardQueryHookResult = ReturnType<typeof useBookingPanelCardQuery>;
export type BookingPanelCardQueryLazyQueryHookResult = ReturnType<typeof useBookingPanelCardQueryLazyQuery>;
export type BookingPanelCardQueryQueryResult = Apollo.QueryResult<Types.BookingPanelCardQuery, Types.BookingPanelCardQueryVariables>;
export const CalendarDaySummaryDocument = gql`
    query CalendarDaySummary($morningStartISOStr: DateTime!, $morningEndISOStr: DateTime!, $afternoonStartISOStr: DateTime!, $afternoonEndISOStr: DateTime!, $eveningStartISOStr: DateTime!, $eveningEndISOStr: DateTime!) {
  tradesperson {
    id
    morning: jobStats(
      filter: {booked: true, fulfillmentWindowStartAfter: $morningStartISOStr, fulfillmentWindowEndBefore: $morningEndISOStr}
    ) {
      total
    }
    afternoon: jobStats(
      filter: {booked: true, fulfillmentWindowStartAfter: $afternoonStartISOStr, fulfillmentWindowEndBefore: $afternoonEndISOStr}
    ) {
      total
    }
    evening: jobStats(
      filter: {booked: true, fulfillmentWindowStartAfter: $eveningStartISOStr, fulfillmentWindowEndBefore: $eveningEndISOStr}
    ) {
      total
    }
  }
}
    `;

/**
 * __useCalendarDaySummary__
 *
 * To run a query within a React component, call `useCalendarDaySummary` and pass it any options that fit your needs.
 * When your component renders, `useCalendarDaySummary` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarDaySummary({
 *   variables: {
 *      morningStartISOStr: // value for 'morningStartISOStr'
 *      morningEndISOStr: // value for 'morningEndISOStr'
 *      afternoonStartISOStr: // value for 'afternoonStartISOStr'
 *      afternoonEndISOStr: // value for 'afternoonEndISOStr'
 *      eveningStartISOStr: // value for 'eveningStartISOStr'
 *      eveningEndISOStr: // value for 'eveningEndISOStr'
 *   },
 * });
 */
export function useCalendarDaySummary(baseOptions: Apollo.QueryHookOptions<Types.CalendarDaySummary, Types.CalendarDaySummaryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CalendarDaySummary, Types.CalendarDaySummaryVariables>(CalendarDaySummaryDocument, options);
      }
export function useCalendarDaySummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CalendarDaySummary, Types.CalendarDaySummaryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CalendarDaySummary, Types.CalendarDaySummaryVariables>(CalendarDaySummaryDocument, options);
        }
export type CalendarDaySummaryHookResult = ReturnType<typeof useCalendarDaySummary>;
export type CalendarDaySummaryLazyQueryHookResult = ReturnType<typeof useCalendarDaySummaryLazyQuery>;
export type CalendarDaySummaryQueryResult = Apollo.QueryResult<Types.CalendarDaySummary, Types.CalendarDaySummaryVariables>;
export const QuoteDetailsDocument = gql`
    query QuoteDetails($jobId: ID!) {
  latestQuote(jobId: $jobId) {
    id
    approvalStatus
    quoteNumber
    total
    lineItems {
      id
      description
      quantity
      amount
      taxPercentage
      total
    }
  }
}
    `;

/**
 * __useQuoteDetails__
 *
 * To run a query within a React component, call `useQuoteDetails` and pass it any options that fit your needs.
 * When your component renders, `useQuoteDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteDetails({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useQuoteDetails(baseOptions: Apollo.QueryHookOptions<Types.QuoteDetails, Types.QuoteDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuoteDetails, Types.QuoteDetailsVariables>(QuoteDetailsDocument, options);
      }
export function useQuoteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuoteDetails, Types.QuoteDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuoteDetails, Types.QuoteDetailsVariables>(QuoteDetailsDocument, options);
        }
export type QuoteDetailsHookResult = ReturnType<typeof useQuoteDetails>;
export type QuoteDetailsLazyQueryHookResult = ReturnType<typeof useQuoteDetailsLazyQuery>;
export type QuoteDetailsQueryResult = Apollo.QueryResult<Types.QuoteDetails, Types.QuoteDetailsVariables>;
export const RescheduleAppointmentDetailsQueryDocument = gql`
    query RescheduleAppointmentDetailsQuery($appointmentId: ID!) {
  appointment(id: $appointmentId) {
    id
    jobId
    showNotifyCustomerPromptOnManualBooking
  }
}
    `;

/**
 * __useRescheduleAppointmentDetailsQuery__
 *
 * To run a query within a React component, call `useRescheduleAppointmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAppointmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleAppointmentDetailsQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useRescheduleAppointmentDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.RescheduleAppointmentDetailsQuery, Types.RescheduleAppointmentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RescheduleAppointmentDetailsQuery, Types.RescheduleAppointmentDetailsQueryVariables>(RescheduleAppointmentDetailsQueryDocument, options);
      }
export function useRescheduleAppointmentDetailsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RescheduleAppointmentDetailsQuery, Types.RescheduleAppointmentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RescheduleAppointmentDetailsQuery, Types.RescheduleAppointmentDetailsQueryVariables>(RescheduleAppointmentDetailsQueryDocument, options);
        }
export type RescheduleAppointmentDetailsQueryHookResult = ReturnType<typeof useRescheduleAppointmentDetailsQuery>;
export type RescheduleAppointmentDetailsQueryLazyQueryHookResult = ReturnType<typeof useRescheduleAppointmentDetailsQueryLazyQuery>;
export type RescheduleAppointmentDetailsQueryQueryResult = Apollo.QueryResult<Types.RescheduleAppointmentDetailsQuery, Types.RescheduleAppointmentDetailsQueryVariables>;
export const GroupPermissionsDocument = gql`
    query GroupPermissions {
  groupPermissions {
    id
    name
    permissions {
      id
      name
      codename
    }
  }
}
    `;

/**
 * __useGroupPermissions__
 *
 * To run a query within a React component, call `useGroupPermissions` and pass it any options that fit your needs.
 * When your component renders, `useGroupPermissions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPermissions({
 *   variables: {
 *   },
 * });
 */
export function useGroupPermissions(baseOptions?: Apollo.QueryHookOptions<Types.GroupPermissions, Types.GroupPermissionsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GroupPermissions, Types.GroupPermissionsVariables>(GroupPermissionsDocument, options);
      }
export function useGroupPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GroupPermissions, Types.GroupPermissionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GroupPermissions, Types.GroupPermissionsVariables>(GroupPermissionsDocument, options);
        }
export type GroupPermissionsHookResult = ReturnType<typeof useGroupPermissions>;
export type GroupPermissionsLazyQueryHookResult = ReturnType<typeof useGroupPermissionsLazyQuery>;
export type GroupPermissionsQueryResult = Apollo.QueryResult<Types.GroupPermissions, Types.GroupPermissionsVariables>;
export const TradespersonCompaniesListDocument = gql`
    query TradespersonCompaniesList($companyId: ID!) {
  companyTradespersons(companyId: $companyId) {
    id
    groupIds
    status
    createdAt
    invitationId
    tradesperson {
      emailAddress
      fullName
      phoneNumber {
        id
        phoneNumber
      }
      id
    }
  }
}
    `;

/**
 * __useTradespersonCompaniesList__
 *
 * To run a query within a React component, call `useTradespersonCompaniesList` and pass it any options that fit your needs.
 * When your component renders, `useTradespersonCompaniesList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradespersonCompaniesList({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTradespersonCompaniesList(baseOptions: Apollo.QueryHookOptions<Types.TradespersonCompaniesList, Types.TradespersonCompaniesListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradespersonCompaniesList, Types.TradespersonCompaniesListVariables>(TradespersonCompaniesListDocument, options);
      }
export function useTradespersonCompaniesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradespersonCompaniesList, Types.TradespersonCompaniesListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradespersonCompaniesList, Types.TradespersonCompaniesListVariables>(TradespersonCompaniesListDocument, options);
        }
export type TradespersonCompaniesListHookResult = ReturnType<typeof useTradespersonCompaniesList>;
export type TradespersonCompaniesListLazyQueryHookResult = ReturnType<typeof useTradespersonCompaniesListLazyQuery>;
export type TradespersonCompaniesListQueryResult = Apollo.QueryResult<Types.TradespersonCompaniesList, Types.TradespersonCompaniesListVariables>;
export const TradespersonCompaniesDocument = gql`
    query TradespersonCompanies {
  tradesperson {
    id
    companies {
      companyId
      companyName
      groupIds
    }
  }
}
    `;

/**
 * __useTradespersonCompanies__
 *
 * To run a query within a React component, call `useTradespersonCompanies` and pass it any options that fit your needs.
 * When your component renders, `useTradespersonCompanies` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradespersonCompanies({
 *   variables: {
 *   },
 * });
 */
export function useTradespersonCompanies(baseOptions?: Apollo.QueryHookOptions<Types.TradespersonCompanies, Types.TradespersonCompaniesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TradespersonCompanies, Types.TradespersonCompaniesVariables>(TradespersonCompaniesDocument, options);
      }
export function useTradespersonCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TradespersonCompanies, Types.TradespersonCompaniesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TradespersonCompanies, Types.TradespersonCompaniesVariables>(TradespersonCompaniesDocument, options);
        }
export type TradespersonCompaniesHookResult = ReturnType<typeof useTradespersonCompanies>;
export type TradespersonCompaniesLazyQueryHookResult = ReturnType<typeof useTradespersonCompaniesLazyQuery>;
export type TradespersonCompaniesQueryResult = Apollo.QueryResult<Types.TradespersonCompanies, Types.TradespersonCompaniesVariables>;