import { onError } from '@apollo/client/link/error';

import { removeRefreshInProgress } from 'src/utils/storage/utils';

import { breadcrumbOperation, isUnrecoverableRefreshError } from '../utils';
import { AuthLinkProps } from './types';

export const retryRefreshIfPossible = ({ apolloEvents }: AuthLinkProps) => {
  return onError(({ graphQLErrors, operation, forward }) => {
    // This link is solely for checking on the result of the
    // RefreshAuthTokens operation. If it's not that operation - skip
    if (operation.operationName !== 'RefreshAuthTokens') return;

    // If there were no errors during the refresh process, there's no need to retry
    if (!graphQLErrors) return;

    // If there was an unrecoverable error it's impossible to retry
    if (isUnrecoverableRefreshError(graphQLErrors)) {
      removeRefreshInProgress();
      apolloEvents.emitTokenRefreshFailed();
      return;
    }

    breadcrumbOperation({
      message: 'Retrying refresh operation',
      operation,
      type: 'AuthLink',
      extraData: {
        graphQLErrors: JSON.stringify(graphQLErrors, null, 2),
      },
    });

    return forward(operation);
  });
};
