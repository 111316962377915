const INTERCOM_CLASS = 'intercom-lightweight-app';

export const hideIntercomButton = () => {
  const chatBubble = document.querySelector(`.${INTERCOM_CLASS}`);
  if (!chatBubble) return;
  if (chatBubble.classList.contains('hidden')) return;

  chatBubble.classList.add('hidden');
};

export const showIntercomButton = () => {
  const chatBubble = document.querySelector(`.${INTERCOM_CLASS}`);
  if (!chatBubble) return;

  chatBubble.classList.remove('hidden');
};
