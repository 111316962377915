import { PartnerFragment } from 'kantan-utils/lib/apollo/__generated__/types';
import { DateTime } from 'luxon';

import { Notification } from 'src/apollo/__generated__/types';
import { hasUnreadNotifications } from 'src/components/Notifications/hooks/useNotifications';

import { getPartnerName } from './partners';
import { getItem, removeItem, setItem } from './storage';

export const setLastSeenNotifications = (date?: DateTime) => {
  setItem('notificationsLastSeen', date ? date : DateTime.utc());
};

export const getLastSeenNotificationsFromStorage = () => {
  const lastSeenISO = getItem('notificationsLastSeen');
  if (!!lastSeenISO) return DateTime.fromISO(lastSeenISO!);
  return;
};

export const checkForNewNotifications = (notifications: Notification[]) => {
  const lastSeen = getLastSeenNotificationsFromStorage();

  if (notifications.length === 0) return false;

  if (!lastSeen) return true;

  const hasUnread = notifications.some(
    (notification) => DateTime.fromISO(notification.createdAt) > lastSeen,
  );

  hasUnreadNotifications(hasUnread);

  return hasUnread;
};

export const clearLastSeenNotifications = () => {
  removeItem('notificationsLastSeen');
};

export const isNewNotification = (createAtISO?: string) => {
  const lastSeen = getLastSeenNotificationsFromStorage();
  if (!createAtISO) return false; // No time stamp, assume seen
  return lastSeen && lastSeen < DateTime.fromISO(createAtISO!);
};

interface PartnerFragments {
  [key: string]: PartnerFragment | undefined;
}

export const getPartner = (partners: PartnerFragments, partnerId: string) => {
  return Object.values(partners || {}).find((partner) => {
    return partner?.partnerId === partnerId;
  });
};

export const getPartnerText = (partner: PartnerFragment) => {
  return partner ? ` by ${getPartnerName(partner)}` : '';
};

export const getPartnerTextFromList = (
  partners?: PartnerFragments,
  partnerId?: string,
) => {
  if (!partners || !partnerId) return '';

  const partner = getPartner(partners, partnerId);

  return partner && getPartnerText(partner);
};
