import { makeVar } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import { isNotNullOrUndefined, useParseQuery } from 'kantan-utils';
import { useState } from 'react';

import { GetNotificationsVariables } from 'src/apollo/__generated__/types';
import { isQueryInFlight } from 'src/apollo/hooks/isQueryInFlight';
import { useGetNotifications } from 'src/apollo/queries';

export const hasUnreadNotifications = makeVar(false);

export const PAGE_SIZE = 10;
const DEFAULT_NOTIFICAITON_POLL_INTERVAL = 30000;

interface NotificationsParams {
  filter?: GetNotificationsVariables['filter'];
  shouldPoll?: boolean;
  skip?: boolean;
}

export const useNotifications = (
  { filter, shouldPoll, skip = false }: NotificationsParams = {},
  onCompleted?: () => void,
) => {
  const filters: Partial<GetNotificationsVariables> = {
    filter,
    apolloQueryKey: shouldPoll ? 'poll' : 'regular',
  };

  const notificationsQuery = useGetNotifications({
    variables: {
      ...filters,
      paging: {
        first: PAGE_SIZE,
      },
    },
    pollInterval: shouldPoll ? DEFAULT_NOTIFICAITON_POLL_INTERVAL : undefined,
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      captureException(error);
    },
    onCompleted,
    skip,
  });
  const isLoading = isQueryInFlight(notificationsQuery);
  const [loadMoreRequests, setLoadMoreRequests] = useState(0);

  const onLoadMore = () => {
    if (
      isLoading ||
      !notificationsQuery.data?.tradesperson?.notifications?.pageInfo
        .hasNextPage
    )
      return;

    setLoadMoreRequests((n) => ++n);

    void notificationsQuery
      .fetchMore({
        variables: {
          ...filters,
          paging: {
            first: PAGE_SIZE,
            after:
              notificationsQuery.data?.tradesperson?.notifications?.pageInfo
                .nextCursor,
          },
        },
      })
      .finally(() => {
        setLoadMoreRequests((n) => --n);
      });
  };

  const parsedNotificationsQuery = useParseQuery(
    notificationsQuery,
    ({ tradesperson }) =>
      tradesperson?.notifications?.edges
        ?.map((edge) => edge?.node)
        .filter(isNotNullOrUndefined) ?? [],
  );

  return {
    notificationsQuery: parsedNotificationsQuery,
    onLoadMore,
    isLoading,
    isLoadingMore: loadMoreRequests > 0,
  };
};
