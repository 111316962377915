import { ApolloLink } from '@apollo/client';

import { getItem } from 'src/utils/storage';

export const attachHeaderIfRequiredLink = () => {
  return new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    const { headers, requiresAuth } = context;
    const accessToken = getItem('ACCESS_TOKEN');
    const refreshToken = getItem('REFRESH_TOKEN');

    // No need to attach a header if no auth is required
    if (requiresAuth === false) return forward(operation);

    // No refresh token means the user is not authenticated. No point in firing the query since it'll fail
    // If it's within the webview, this is normal behavior, as there's never a refresh token in that case
    if (!refreshToken) {
      const isWebView = getItem('WEBVIEW');
      if (!isWebView) return null;
    }

    // No access token means no header can be attached
    if (!accessToken) return forward(operation);

    operation.setContext(() => ({
      ...context,
      headers: { ...headers, authorization: `Bearer ${accessToken}` },
    }));

    return forward(operation);
  });
};
