import jwtDecode from 'jwt-decode';
import { createErrorClass } from 'kantan-utils';

import { AccessToken, User } from '../types';

const DecodeTokenError = createErrorClass('DecodeTokenError');
const STANDARD_CLAIMS = ['iss', 'sub', 'exp', 'nbf', 'iat', 'jti', 'ctx'];

const getExtraClaims = (token: AccessToken) => {
  const extraClaims: Record<string, any> = {};

  for (const key of Object.keys(token)) {
    if (STANDARD_CLAIMS.includes(key)) continue;
    extraClaims[key] = token[key];
  }

  return extraClaims;
};

export const decodeToken = (token?: string): User | undefined => {
  if (!token) return undefined;

  try {
    const decodedToken = jwtDecode<AccessToken>(token);
    if (!decodedToken.sub) return;

    const extraClaims = getExtraClaims(decodedToken);
    const { sub: userId, ctx } = decodedToken;
    return { userId, context: ctx ?? {}, extraClaims };
  } catch (err) {
    new DecodeTokenError('Failed to decode JWT', { extra: { jwt: token } });
    return;
  }
};
