import { useEffect, useState } from 'react';

import { useAuth } from 'src/apollo/auth/AuthProvider';

import {
  DefaultImpersonationPermissions,
  DefaultStandardPermissions,
  PermissionValues,
} from '../defaults';
import { calculatePermissions } from '../utils/calculatePermissions';

export const usePermissions = (): PermissionValues => {
  const { user, isImpersonating } = useAuth();
  const [standardPermissions, setStandardPermissions] =
    useState<PermissionValues>(DefaultStandardPermissions);
  const [impersonationPermissions, setImpersonationPermissions] =
    useState<PermissionValues>(DefaultImpersonationPermissions);

  const [permissions, setPermissions] =
    useState<PermissionValues>(standardPermissions);

  useEffect(() => {
    setStandardPermissions(
      calculatePermissions(
        user?.context.permissions as string[],
        DefaultStandardPermissions,
      ),
    );

    setImpersonationPermissions(
      calculatePermissions(
        user?.context.impersonate_permissions as string[],
        DefaultImpersonationPermissions,
      ),
    );
  }, [user?.context]);

  useEffect(() => {
    if (isImpersonating) setPermissions(impersonationPermissions);
    else setPermissions(standardPermissions);
  }, [isImpersonating, impersonationPermissions, standardPermissions]);

  return { ...permissions };
};
