import { DateTime } from 'luxon';

import {
  getItem,
  onUnload,
  removeItem,
  removeUnloadEvent,
  setItem,
} from './storage';

const REFRESH_IDENTIFIER = 'REFRESH_UNLOAD_CALLBACK';
const REFRESH_TTL = 2500;

export const setRefreshInProgress = () => {
  const isRefreshInProgress = getItem('REFRESH_IN_PROGRESS');
  if (isRefreshInProgress) return;

  setItem('REFRESH_IN_PROGRESS', DateTime.utc().toISO());
  onUnload(() => removeItem('REFRESH_IN_PROGRESS'), REFRESH_IDENTIFIER);
};

export const removeRefreshInProgress = () => {
  removeItem('REFRESH_IN_PROGRESS');
  removeUnloadEvent(REFRESH_IDENTIFIER);
};

export const isRefreshInProgressExpired = () => {
  const isRefreshInProgress = getItem('REFRESH_IN_PROGRESS');
  if (!isRefreshInProgress) return false;

  try {
    const date = DateTime.fromISO(isRefreshInProgress);
    if (!date.isValid) return true;

    return date.toUTC().diffNow().as('milliseconds') < -REFRESH_TTL;
  } catch {
    return true; //TODO: (Marc) Change this to false after a few days
  }
};
