import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as ApolloClientProvider,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { SentryLink } from 'apollo-link-sentry';
import {
  createApolloCache,
  permissionsLink,
  randomDelayLink,
  randomFailureLink,
  setClientAwarenessLink,
} from 'kantan-utils';
import React, { PropsWithChildren, useMemo } from 'react';

import { useCounter } from 'src/hooks/useCounter';

import fragmentMatching from '../__generated__/fragmentMatching';
import { useApolloEvents } from '../auth/hooks';
import { useReinitApolloClient as useReinitApolloClientRequested } from '../hooks/useReinitApolloClient';
import { authLink } from '../links';

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
  },
  attempts: {
    max: 3,
    retryIf: (error) => Boolean(error),
  },
});

const sentryLink = new SentryLink({
  breadcrumb: {
    enable: true,
    includeVariables: true,
    includeError: true,
  },
});

const httpLink = new BatchHttpLink({
  uri: process.env.NEXT_PUBLIC_KANTAN_GATEWAY_URI,
});

export const ApolloProvider = ({ children }: PropsWithChildren<{}>) => {
  const apolloEvents = useApolloEvents();
  const possibleTypes = fragmentMatching.possibleTypes;
  const clientCounter = useCounter();
  useReinitApolloClientRequested(() => {
    clientCounter.increment();
  });

  const client = useMemo(() => {
    return new ApolloClient({
      link: ApolloLink.from([
        retryLink,
        permissionsLink({ onForbiddenError: apolloEvents.emitForbiddenError }),
        authLink({ apolloEvents }),
        setClientAwarenessLink({ name: 'Web', version: '0.0.1' }),
        randomDelayLink(),
        randomFailureLink(),
        // sentryLink, //TODO: resolve: Sentry Link is throwing an error when trying to log network errors
        httpLink,
      ]),
      cache: createApolloCache({ possibleTypes }),
      connectToDevTools: false, //TODO: Add env check
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloEvents, clientCounter.value]);
  return (
    <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
  );
};
