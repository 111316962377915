import {
  ApolloError,
  FetchResult,
  fromPromise,
  NextLink,
  Operation,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createOperation } from '@apollo/client/link/utils';
import { sha1 } from 'hash.js';

import { ApolloEvents } from 'src/apollo/auth/hooks';
import { RefreshAuthTokensDocument } from 'src/apollo/mutations';
import { getItem, setItem } from 'src/utils/storage';
import {
  isRefreshInProgressExpired,
  removeRefreshInProgress,
  setRefreshInProgress,
} from 'src/utils/storage/utils';

import {
  breadcrumbOperation,
  isErrorWithCode,
  isUnrecoverableRefreshError,
  waitForRefreshToComplete,
} from '../utils';
import { AuthLinkProps } from './types';

const createRefreshOperation = (refreshToken: string) => {
  const op = createOperation(
    {},
    {
      operationName: 'RefreshAuthTokens',
      query: RefreshAuthTokensDocument,
      variables: { refreshToken },
    },
  );
  op.setContext({ requiresAuth: false });
  return op;
};

export const retryOnUnauthenticatedLink = (props: AuthLinkProps) => {
  return onError(({ graphQLErrors, operation, forward }) => {
    const isUnauthenticated = graphQLErrors?.some(
      isErrorWithCode('UNAUTHENTICATED'),
    );

    // This logic only applies to unauthenticated errors
    // If this is any other type of error, return
    if (!isUnauthenticated) return;

    const { apolloEvents } = props;
    const isRefreshInProgress = getItem('REFRESH_IN_PROGRESS');

    // If refresh in progress is expired, remove it and retry
    if (isRefreshInProgressExpired()) removeRefreshInProgress();

    // If refresh is already in progress wait for it to complete
    if (isRefreshInProgress) {
      return fromPromise(waitForRefreshToComplete(forward, operation));
    }

    //Add breadcrumb for sentry
    breadcrumbOperation({
      message: 'Operation returned UNAUTHENTICATED, triggering refresh',
      operation,
      type: 'AuthLink',
    });

    const refreshToken = getItem('REFRESH_TOKEN');
    const accessToken = getItem('ACCESS_TOKEN');
    if (!refreshToken) {
      if (accessToken)
        apolloEvents.emitNoRefreshToken({ operation, forward, graphQLErrors });
      return forward(operation);
    }

    setRefreshInProgress();

    const refreshOperation = createRefreshOperation(refreshToken);
    return forward(refreshOperation).flatMap((res) => {
      return onRefreshOperationComplete(res, operation, forward, apolloEvents);
    });
  });
};

type RefreshResult = FetchResult<
  Record<string, any>,
  Record<string, any>,
  Record<string, any>
>;

const onRefreshOperationComplete = (
  result: RefreshResult,
  operation: Operation,
  forward: NextLink,
  apolloEvents: ApolloEvents,
) => {
  const { errors, data } = result;
  const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
    data?.refreshAuthTokens ?? {};

  // Refreshing has failed with specific unrecoverable errors
  if (errors && isUnrecoverableRefreshError(errors)) {
    const error = new ApolloError({
      graphQLErrors: errors,
      errorMessage: 'Refresh token operation failed',
    });
    apolloEvents.emitTokenRefreshFailed(error);
    removeRefreshInProgress();
    throw error;
  }

  // Refreshing has failed with an other errors
  if (errors) {
    const error = new ApolloError({
      graphQLErrors: errors,
      errorMessage: 'Error during refresh operation',
    });
    apolloEvents.emitTokenRefreshFailed(error);
    removeRefreshInProgress();
    throw error;
  }

  // There are no errors, but no tokens were returned, so refreshing is impossible
  if (!newAccessToken && !newRefreshToken) {
    const error = new ApolloError({
      errorMessage: 'No refresh token returned',
    });
    apolloEvents.emitTokenRefreshFailed(error);
    removeRefreshInProgress();
    throw error;
  }

  // Set the new tokens in local storage & refreshing to no longer in progress
  setItem('ACCESS_TOKEN', newAccessToken);
  setItem('REFRESH_TOKEN', newRefreshToken);
  removeRefreshInProgress();

  // Add logging with hashed tokens as extra data
  breadcrumbOperation({
    message: 'Refreshed tokens',
    operation,
    type: 'AuthLink',
    extraData: {
      accessToken: sha1().update(newAccessToken).digest('hex'),
      refreshToken: sha1().update(newRefreshToken).digest('hex'),
    },
  });

  return forward(operation);
};
