import { useCallback, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';

import { observeItem, setItem } from 'src/utils/storage';

export const useSessionUrl = (): [
  string | undefined,
  (url?: string) => void,
] => {
  const [url, setUrl] = useState<string>();
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    const sub = observeItem<string>('SESSION_URL').subscribe((url) => {
      setUrl(url);
    });

    setSubscription(sub);
    return () => subscription?.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSessionUrl = useCallback((url?: string) => {
    setItem('SESSION_URL', url);
  }, []);

  return [url, setSessionUrl];
};
