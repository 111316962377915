import { useCallback } from 'react';

import { useReinitApolloClient } from 'src/apollo/hooks/useReinitApolloClient';
import { getItem, removeItem, setItem } from 'src/utils/storage';

interface UseImpersonationMethodsProps {
  onSetImpersonationTokensSuccess?: () => void;
  onRemoveImpersonationTokensSuccess?: (
    refreshToken?: string,
    accessToken?: string,
  ) => void;
}

interface UseImpersonationMethods {
  setImpersonationTokens: (refreshToken: string, accessToken: string) => void;
  removeImpersonationTokens: () => void;
}

export const useImpersonationMethods = ({
  onSetImpersonationTokensSuccess,
  onRemoveImpersonationTokensSuccess,
}: UseImpersonationMethodsProps = {}): UseImpersonationMethods => {
  const reinitApolloClient = useReinitApolloClient();

  const setImpersonationTokens = useCallback(
    (refreshToken: string, accessToken: string) => {
      const realAccessToken = getItem('ACCESS_TOKEN');
      const realRefreshToken = getItem('REFRESH_TOKEN');
      setItem('BAK_ACCESS_TOKEN', realAccessToken);
      setItem('BAK_REFRESH_TOKEN', realRefreshToken);

      setItem('REFRESH_TOKEN', refreshToken);
      setItem('ACCESS_TOKEN', accessToken);

      reinitApolloClient();
      onSetImpersonationTokensSuccess?.();
    },
    [onSetImpersonationTokensSuccess, reinitApolloClient],
  );

  const removeImpersonationTokens = useCallback(() => {
    const realAccessToken = getItem('BAK_ACCESS_TOKEN');
    const realRefreshToken = getItem('BAK_REFRESH_TOKEN');
    removeItem('BAK_ACCESS_TOKEN');
    removeItem('BAK_REFRESH_TOKEN');

    setItem('REFRESH_TOKEN', realRefreshToken);
    setItem('ACCESS_TOKEN', realAccessToken);
    removeItem('REFRESH_IN_PROGRESS');

    reinitApolloClient();
    onRemoveImpersonationTokensSuccess?.(realRefreshToken, realAccessToken);
  }, [onRemoveImpersonationTokensSuccess, reinitApolloClient]);

  return { setImpersonationTokens, removeImpersonationTokens };
};
