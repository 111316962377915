import { Partners } from 'kantan-utils';
import { PartnerFragment } from 'kantan-utils/lib/apollo/__generated__/types';

import { Maybe } from 'src/apollo/__generated__/types';

export const isCHPPartner = (partner: { slug?: string }): boolean => {
  return (
    !!partner.slug &&
    ['corgi', 'kantan-internal', 'kantan-test'].includes(partner.slug)
  );
};

export const getPartnerName = (partner?: Maybe<PartnerFragment>) => {
  if (!partner) return undefined;

  if (isCHPPartner(partner)) {
    return 'CHP';
  }

  return partner.name;
};

export const isMarketplacePartner = (partnerId: string, partners: Partners) => {
  const partner = Object.values(partners).find(
    (partner) => partner?.partnerId === partnerId,
  );

  const partnerType = partner ? partner?.type : '';
  return ['ktn', 'ovoOnDemand', 'fcg', 'ovoSupportPackage'].includes(
    partnerType,
  );
};

export function mapPartnerToKey(
  partners: Partners | undefined,
  partnerId: string,
) {
  if (!partners) return 'ktn';
  return (
    Object.values(partners).find((partner) => partner?.partnerId === partnerId)
      ?.type ?? 'ktn'
  );
}

export const mapPartner = (partnerKey?: string) => {
  if (isCHPPartner({ slug: partnerKey })) return 'chp';
  switch (partnerKey) {
    case 'chp':
      return 'chp';
    case 'private':
      return 'private';
    default:
      return 'ktn';
  }
};

const partnerKeys = new Set(['chp', 'ktn', 'private']);
export const isListGroupPartner = (
  listGroup: string | string[] | undefined,
) => {
  return partnerKeys.has(listGroup?.toString() || '');
};

export const getJobBrand = (partnerKey: string | undefined) => {
  switch (partnerKey) {
    case 'chp':
      return 'chp';
    case 'private':
      return 'private';
    default:
      return 'kantan';
  }
};
