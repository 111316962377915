/* eslint-disable no-restricted-syntax */
import { ReplaySubject } from 'rxjs';

import { PossibleStorageKey } from './storage';

export const observeSessionItem = <T>(
  key: PossibleStorageKey,
): ReplaySubject<T | undefined> => {
  const subject = new ReplaySubject<T | undefined>();

  window.addEventListener('storage', (e) => {
    if (e.key !== key) return;
    if (e.storageArea !== window.sessionStorage) return;

    if (!e.newValue) {
      subject.next(undefined);
    }

    let newValue;
    try {
      newValue = JSON.parse(e.newValue as string);
    } catch {
      newValue = e.newValue;
    }

    subject.next(newValue as T);
  });

  subject.next(getSessionItem<T>(key) as T);
  return subject;
};

export const getSessionItem = <T = any>(
  key: PossibleStorageKey,
): T | undefined => {
  const currentValue = window.sessionStorage.getItem(key);
  try {
    return currentValue ? JSON.parse(currentValue) : undefined;
  } catch {
    return currentValue ? (currentValue as unknown as T) : undefined;
  }
};

export const setSessionItem = (key: PossibleStorageKey, value: any) => {
  const parsedValue = JSON.stringify(value);
  const oldValue = getSessionItem<any>(key);
  window.sessionStorage.setItem(key, parsedValue);

  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      oldValue,
      newValue: value,
      storageArea: window.sessionStorage,
    }),
  );
};

export const removeSessionItem = (key: PossibleStorageKey) => {
  const oldValue = getSessionItem<any>(key);
  window.sessionStorage.removeItem(key);

  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      oldValue,
      newValue: undefined,
      storageArea: window.sessionStorage,
    }),
  );
};
