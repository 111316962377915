import { Permissions, PermissionValues } from '../defaults';
import { StringPermissions } from '../types';

export const permissionMaps: Record<StringPermissions, Permissions> = {
  'jobsheets.change_jobsheet': 'canEditJobsheet',
  'jobsheets.change_submitted_jobsheet': 'canEditSubmittedJobsheet',
  'kantan_auth.can_view_all_partners': 'canViewAllPartners',
};

export const calculatePermissions = (
  permissions: string[] | undefined = [],
  defaults: PermissionValues,
) => {
  const calculatedPermissions = { ...defaults };

  permissions?.forEach((permission) => {
    calculatedPermissions[permissionMaps[permission]] = true;
  });

  return calculatedPermissions;
};
