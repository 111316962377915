import { useEffect, useState } from 'react';
import { combineLatest, Subscription } from 'rxjs';

import { observeItem, observeSessionItem } from 'src/utils/storage';

interface UseTokens {
  accessToken?: string;
  refreshToken?: string;
  tokensReady: boolean;
}

export const useTokens = (): UseTokens => {
  const [accessToken, setAccessToken] = useState<string>();
  const [refreshToken, setRefreshToken] = useState<string>();
  const [tokensReady, setTokensReady] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    const accessObservable = observeItem<string>('ACCESS_TOKEN');
    const refreshObservable = observeItem<string>('REFRESH_TOKEN');

    const sub = combineLatest([accessObservable, refreshObservable]).subscribe(
      ([accessToken, refreshToken]) => {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setTokensReady(true);
      },
    );

    setSubscription(sub);

    return () => subscription?.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { accessToken, refreshToken, tokensReady };
};
