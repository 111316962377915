import React, { ReactElement } from 'react';

export interface Alert {
  message: string | React.ReactNode;
  type: 'success' | 'warning' | 'error';
  canDismiss?: boolean;
  callToAction?: ReactElement;
  id: string;
}

export enum ActionType {
  ADD_ALERT = 'ADD_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
  HIDE_OVERLAY = 'HIDE_OVERLAY',
}

export interface AlertState {
  alerts: Alert[];
  showOverlay: boolean;
}

export interface AlertContext {
  state: AlertState;
  dispatch: React.Dispatch<any>;
}
export interface Action {
  type: ActionType;
  payload: any;
}
