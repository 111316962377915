import { ApolloLink } from '@apollo/client';

import { removeRefreshInProgress } from 'src/utils/storage/utils';

export const onSuccessLink = () => {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      if (!data.errors) removeRefreshInProgress();

      return data;
    });
  });
};
