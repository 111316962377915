import React, {
  PropsWithChildren,
  ReactElement,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
interface TooltipProps {
  message: string | ReactElement;
  position?: 'top' | 'left' | 'bottom' | 'right';
  disabled?: boolean;
  extraStyles?: string;
  interactive?: boolean;
}
export const Tooltip = ({
  message,
  children,
  position = 'top',
  disabled = false,
  interactive = false,
  extraStyles,
}: PropsWithChildren<TooltipProps>) => {
  let [show, setShow] = useState(false);
  const [point, setPoint] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLSpanElement>(null);
  let timeout: ReturnType<typeof setTimeout>;
  return (
    <>
      {disabled
        ? children
        : React.cloneElement(children as ReactElement, {
            onPointerEnter: (e: MouseEvent) => {
              const newPoint = getPoint(
                e.target as HTMLElement,
                tooltipRef.current as HTMLElement,
                position,
              );

              setShow(true);
              setPoint(newPoint);
            },
            onPointerLeave: () => {
              timeout = setTimeout(
                () => {
                  setShow(false);
                },
                interactive ? 1000 : 0,
              );
            },
          })}
      {!disabled && (
        <Portal>
          <span
            onMouseEnter={() => {
              if (!!timeout) {
                clearTimeout(timeout);
              }
              interactive && setShow(true);
            }}
            onMouseLeave={() => {
              setShow(false);
            }}
            id="tooltip"
            data-testid="tooltip"
            ref={tooltipRef}
            className={`p-2 fixed bg-tooltip text-white font-sm 
            ${
              show ? 'pointer-events-auto' : 'pointer-events-none'
            } rounded-md z-50 inline-block max-w-[85%] md:max-w-[60%] ${
              show ? 'opacity-100' : 'opacity-0'
            } ${extraStyles}`}
            style={{ top: point.y, left: point.x }}
          >
            {message}
          </span>
        </Portal>
      )}
    </>
  );
};

function Portal({ children }: PropsWithChildren<unknown>) {
  return ReactDOM.createPortal(children, document.body);
}

export function getPoint(
  el: HTMLElement,
  tooltipEl: HTMLElement,
  position: TooltipProps['position'],
  spacers: any = { x: 5, y: 5 },
) {
  const maxIterations = 1;
  const elRect = el.getBoundingClientRect();

  const boundary = {
    left: spacers.x,
    top: spacers.y,
    right: document.body.clientWidth - (tooltipEl.clientWidth + spacers.x),
    bottom: window.innerHeight - (tooltipEl.clientHeight - spacers.y),
  };
  let point = { x: 0, y: 0 };

  function calculatePoint(
    position: TooltipProps['position'],
    iterationCount: number = 0,
  ) {
    const elHeight = el.offsetHeight || el.clientHeight;
    const elWidth = el.offsetWidth || el.clientWidth;
    switch (position) {
      case 'left':
        point.x = elRect.left - (tooltipEl.offsetWidth + spacers.x);
        point.y = elRect.top - Math.abs(elHeight - tooltipEl.offsetHeight) / 2;
        break;
      case 'right':
        point.x = elRect.right + spacers.x;
        point.y = elRect.top - Math.abs(elHeight - tooltipEl.offsetHeight) / 2;
        break;
      case 'top':
        point.x = elRect.left + (elWidth - tooltipEl.offsetWidth) / 2;
        point.y = elRect.top - (tooltipEl.offsetHeight + spacers.y);
        break;
      default:
        point.x = elRect.left + (elWidth - tooltipEl.offsetWidth) / 2;
        point.y = elRect.bottom + spacers.y;
    }

    if (iterationCount <= maxIterations) {
      if (
        (position === 'left' || position === 'right') &&
        (point.x < boundary.left || point.x > boundary.right)
      ) {
        const nextPosition = position === 'left' ? 'right' : 'left';
        point = calculatePoint(nextPosition, iterationCount + 1);
      } else if (
        (position === 'top' || position === 'bottom') &&
        (point.y < boundary.top || point.y > boundary.bottom)
      ) {
        const nextPosition = position === 'top' ? 'bottom' : 'top';
        point = calculatePoint(nextPosition, iterationCount + 1);
      }

      // restrict to boundary
      if (point.x < boundary.left) {
        point.x = boundary.left;
      } else if (point.x > boundary.right) {
        point.x = boundary.right;
      }
      if (point.y < boundary.top) {
        point.y = boundary.top;
      } else if (point.y > boundary.bottom) {
        point.y = boundary.bottom;
      }
    }

    return point;
  }

  return calculatePoint(position);
}
