import { NextLink, Operation, toPromise } from '@apollo/client';
import { addBreadcrumb } from '@sentry/nextjs';
import { GraphQLError } from 'graphql';

import { observeItem } from 'src/utils/storage';
import { removeRefreshInProgress } from 'src/utils/storage/utils';

export const isErrorWithCode = (code: string) => {
  return (error: GraphQLError) => {
    return error.extensions?.code === code;
  };
};

export const isErrorWithStatus = (status: number) => {
  return (error: GraphQLError) => {
    return error.extensions?.response?.status === status;
  };
};

interface BreadcrumbOperationProps {
  message: string;
  operation: Operation;
  type: 'AuthLink' | (string & {});
  extraData?: Record<string, string>;
}

export const breadcrumbOperation = ({
  message,
  operation,
  type,
  extraData = {},
}: BreadcrumbOperationProps) => {
  addBreadcrumb({
    message,
    type,
    category: 'debug',
    data: {
      operation: operation.operationName,
      variables: operation.variables,
      ...extraData,
    },
  });
};

export const isUnrecoverableRefreshError = (
  errors: readonly GraphQLError[],
) => {
  return (
    errors.some(isErrorWithCode('UNAUTHENTICATED')) ||
    errors.some(isErrorWithStatus(400)) ||
    errors.some(isErrorWithStatus(406)) ||
    errors.some(isErrorWithStatus(422))
  );
};

export const operationRequiresAuth = (operation: Operation) => {
  const { requiresAuth = true } = operation.getContext();

  return requiresAuth;
};

export const waitForRefreshToComplete = async (
  forward: NextLink,
  operation: Operation,
) => {
  setTimeout(() => {
    removeRefreshInProgress();
  }, 2500);

  await new Promise<void>((resolve) => {
    observeItem<string>('REFRESH_IN_PROGRESS').subscribe(
      (refreshInProgress) => {
        if (!refreshInProgress) resolve();
      },
    );
  });

  return toPromise(forward(operation));
};
