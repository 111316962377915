import React, { PropsWithChildren, useContext, useReducer } from 'react';

import styles from './alert.module.css';
import { hideOverlay } from './AlertActions';
import { alertReducer } from './AlertReducer';
import { Alerts } from './Alerts';
import { AlertContext, AlertState } from './types';

const initialState: AlertState = {
  alerts: [],
  showOverlay: false,
};
const AlertStore = React.createContext<AlertContext>({
  state: initialState,
  dispatch: () => null,
});
AlertStore.displayName = 'AlertStore';

export const useAlertStore = () => useContext(AlertStore);

export const AlertProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(alertReducer, initialState);
  return (
    <AlertStore.Provider value={{ state, dispatch }}>
      <Alerts />
      {state.showOverlay && (
        <div
          className={state.showOverlay ? 'overlay' : ''}
          onClick={(event) => {
            if (state.showOverlay) {
              dispatch(hideOverlay());
            }
          }}
          data-testid="overlay"
        ></div>
      )}
      {children}
    </AlertStore.Provider>
  );
};
