import { Action, ActionType, ModalState } from './types';
export const modalReducer = (state: ModalState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_MODAL:
      return {
        ...state,
        modals: [...state.modals, action.payload.modal],
      };
    case ActionType.REMOVE_MODAL:
      const updatedModals = state.modals.filter(
        (modal) => modal.id !== action.payload.id,
      );
      return {
        ...state,
        modals: updatedModals,
      };
    default:
      throw new Error('invalid action');
  }
};
