import { Action, ActionType, Alert, AlertState } from './types';

export const alertReducer = (state: AlertState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_ALERT:
      const alert = action.payload.alert as Alert;
      const showOverlay = Boolean(action.payload.showOverlay);
      return {
        ...state,
        showOverlay,
        alerts: [...state.alerts, alert],
      };
    case ActionType.REMOVE_ALERT:
      const updatedAlerts = state.alerts.filter(
        (alert) => alert.id !== action.payload.id,
      );
      return {
        ...state,
        alerts: updatedAlerts,
      };
    case ActionType.HIDE_OVERLAY:
      if (state.alerts.length > 1 || state.alerts[0].canDismiss) {
        return {
          alerts: [],
          showOverlay: false,
        };
      }
      return state;
    default:
      throw new Error('invalid action');
  }
};
