import { captureException } from '@sentry/nextjs';
import { createErrorClass, createSafeContext } from 'kantan-utils';
import { PropsWithChildren } from 'react';

import { hideIntercomButton, showIntercomButton } from 'src/utils/intercom';

import { useApolloEvents, useAuthMethods, useUser } from './hooks';
import { useImpersonationMethods } from './hooks/useImpersonationMethods';
import { User } from './types';

export const [AuthContext, useAuth] = createSafeContext<{
  isImpersonating: boolean;
  isInternalUser: boolean;
  isAuthenticated: boolean;
  isAuthReady: boolean;
  user?: User;
  loginUser: (newRefreshToken: string, newAccessToken: string) => Promise<void>;
  logoutUser: () => Promise<void>;
  setImpersonationTokens: (
    newRefreshToken: string,
    newAccessToken: string,
  ) => void;
  removeImpersonationTokens: () => void;
}>('Auth');

interface AuthProviderProps {
  mockUser?: User;
}

const AuthenticationError = createErrorClass('AuthenticationError');

export const AuthProvider = ({
  children,
  mockUser,
}: PropsWithChildren<AuthProviderProps>) => {
  const {
    user,
    isImpersonating,
    isInternalUser,
    isAuthReady,
    isAuthenticated,
  } = useUser({
    mockUser,
  });

  const { login, logout } = useAuthMethods();
  const { setImpersonationTokens, removeImpersonationTokens } =
    useImpersonationMethods({
      onSetImpersonationTokensSuccess: () => {
        hideIntercomButton();
      },
      onRemoveImpersonationTokensSuccess: () => {
        showIntercomButton();
      },
    });

  useApolloEvents({
    onNoRefreshToken: () => logout(),
    onTokenRefreshFailed: (err) => {
      if (err) {
        captureException(
          AuthenticationError.from(err, 'Failed to fetch new access tokens'),
        );
      }
      logout();
    },
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        isInternalUser,
        isImpersonating,
        isAuthenticated,
        isAuthReady,
        loginUser: login,
        logoutUser: logout,
        setImpersonationTokens,
        removeImpersonationTokens,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
