import { Action, ActionType, Modal } from './types';

export const addModal = (modal: Modal): Action => ({
  type: ActionType.ADD_MODAL,
  payload: { modal },
});

export const removeModal = (id: string): Action => ({
  type: ActionType.REMOVE_MODAL,
  payload: { id },
});
