import { User } from '../types';

type UserContext = User['context'] | undefined;

let _userContext: UserContext;

export const setUserContext = (userContext?: UserContext) => {
  _userContext = userContext;
};

export const getUserContext = (): UserContext => {
  return _userContext;
};
