import { useRef } from 'react';

import { useOnClickOutside } from 'src/utils/useOnClickOutside';

import { ConfirmationModal } from './ConfirmationModal';
import { ContactModal } from './ContactModal';
import { removeModal } from './ModalActions';
import { useModalStore } from './ModalProvider';
import { PromptModal } from './PromptModal';
import { Modal as ModalType } from './types';

export const Modal = ({ modal }: { modal: ModalType }) => {
  let { dispatch } = useModalStore();
  const ref = useRef<HTMLDivElement | null>(null);

  // Use the modal callback if one exists, which already dispatches an
  // event to remove the modal (implemented in the modal's hook).
  // Otherwise dispatch an event to remove the modal.
  useOnClickOutside(ref, () => {
    if (modal.props.onDismiss) {
      modal.props.onDismiss();
    } else {
      dispatch(removeModal(modal.id));
    }
  });
  return (
    <div
      ref={ref}
      className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-50"
    >
      <RenderModal modal={modal} data-testid="modal" />
    </div>
  );
};

const RenderModal = ({ modal }: { modal: ModalType }) => {
  if (modal.type === 'confirmation') {
    return <ConfirmationModal {...modal.props} />;
  } else if (modal.type === 'contact') {
    return <ContactModal {...modal.props} />;
  }
  if (modal.type === 'prompt') {
    return <PromptModal {...modal.props} />;
  }
  return null;
};
