import { useEffect, useState } from 'react';
//@ts-ignore
// eslint-disable-next-line no-restricted-imports
import { default as Smartbanner } from 'react-smartbanner';

import { getItem } from 'src/utils/storage';

interface SmartBannerProps {
  title: string;
  daysHidden: number;
  daysReminder: number;
  button: string;
}

export const SmartBanner = (props: SmartBannerProps) => {
  const [displayBanner, setDisplayBanner] = useState(false);
  useEffect(() => {
    const isWebview = !!getItem('WEBVIEW');
    setDisplayBanner(!isWebview);
  }, []);

  if (!displayBanner) return null;

  return <Smartbanner {...props} />;
};
