import { ReactElement } from 'react';

import { Icon, IconName } from '../Icon';
import styles from './alert.module.css';

interface AlertProps {
  message: string | React.ReactNode;
  type: 'success' | 'warning' | 'error';
  callToAction?: ReactElement;
}

const alertIcon: Record<string, IconName> = {
  success: 'success',
  warning: 'warning',
  error: 'error',
};

const iconPathFill = {
  success: 'fill-green-darker',
  warning: 'fill-orange-darker',
  error: 'fill-red-dark',
};
export const Alert = ({ message, type, callToAction }: AlertProps) => {
  const iconName = alertIcon[type];
  const iconColor = iconPathFill[type];
  return (
    <div
      className={`${styles.alertContainer} ${styles[type]}`}
      data-testid="alert"
    >
      <Icon name={iconName} pathFill={iconColor} data-testid="alert-icon" />
      <div data-testid="alert-text">{message}</div>
      {callToAction ? (
        <div className="col-start-2" data-testid="alert-cta">
          {callToAction}
        </div>
      ) : null}
    </div>
  );
};
