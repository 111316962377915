/* eslint-disable no-restricted-syntax */
import { ReplaySubject } from 'rxjs';

import { PossibleStorageKey } from './storage';

export const observeLocalItem = <T>(
  key: PossibleStorageKey,
): ReplaySubject<T | undefined> => {
  const subject = new ReplaySubject<T | undefined>();

  window.addEventListener('storage', (e) => {
    if (e.key !== key) return;
    if (e.storageArea !== window.localStorage) return;

    if (!e.newValue) {
      subject.next(undefined);
    }

    let newValue;
    try {
      newValue = JSON.parse(e.newValue as string);
    } catch {
      newValue = e.newValue;
    }

    subject.next(newValue as T);
  });

  subject.next(getLocalItem<T>(key) as T);
  return subject;
};

export const getLocalItem = <T = any>(
  key: PossibleStorageKey,
): T | undefined => {
  const currentValue = localStorage.getItem(key);
  try {
    return currentValue ? JSON.parse(currentValue) : undefined;
  } catch {
    return currentValue ? (currentValue as unknown as T) : undefined;
  }
};

export const setLocalItem = (key: PossibleStorageKey, value: any) => {
  const parsedValue = JSON.stringify(value);
  const oldValue = getLocalItem<any>(key);
  localStorage.setItem(key, parsedValue);

  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      oldValue,
      newValue: value,
      storageArea: window.localStorage,
    }),
  );
};

export const removeLocalItem = (key: PossibleStorageKey) => {
  const oldValue = getLocalItem<any>(key);
  localStorage.removeItem(key);

  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      oldValue,
      newValue: undefined,
      storageArea: window.localStorage,
    }),
  );
};
