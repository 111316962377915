import React, { PropsWithChildren, useContext, useReducer } from 'react';

import { Modal } from './Modal';
import { modalReducer } from './ModalReducer';
import { ModalContext, ModalState } from './types';
const initialState: ModalState = {
  modals: [],
};
const ModalStore = React.createContext<ModalContext>({
  state: initialState,
  dispatch: () => {},
});

export const useModalStore = () => useContext(ModalStore);
export const ModalProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  return (
    <ModalStore.Provider value={{ state, dispatch }}>
      {!!state.modals.length && (
        <div className="overlay" data-testid="overlay"></div>
      )}
      {state.modals.map((modal) => (
        <Modal key={modal.id} modal={modal} />
      ))}
      {children}
    </ModalStore.Provider>
  );
};
