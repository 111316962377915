import { Alert } from 'src/components/Alert/Alert';

import styles from './alert.module.css';
import { useAlertStore } from './AlertProvider';

export const Alerts = () => {
  let { state } = useAlertStore();
  return (
    <div className={`${styles.alertsContainer}`}>
      {state.alerts.map((alert) => (
        <Alert
          key={alert.id}
          message={alert.message}
          type={alert.type}
          callToAction={alert.callToAction}
        />
      ))}
    </div>
  );
};
