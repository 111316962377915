import { Spinner } from 'flowbite-react';
import { HTMLInputTypeAttribute, useState } from 'react';

import { CTA } from '../CTA/CTA';
import { Icon, IconName } from '../Icon';

export interface PromptModalProps {
  icon: IconName;
  iconHeight?: number;
  iconFill?: string;
  title: string;
  text: string;
  submitText?: string;
  dismissText?: string;
  inputType?: HTMLInputTypeAttribute;
  loading?: boolean;
  onSubmit?: (input: string) => void;
  onDismiss?: () => void;
}

export const PromptModal = ({
  icon,
  iconHeight,
  iconFill,
  title,
  text,
  dismissText,
  submitText,
  inputType = 'text',
  loading,
  onSubmit = () => {},
  onDismiss = () => {},
}: PromptModalProps) => {
  const subTexts = text.split('\n');
  const [inputText, setInputText] = useState('');

  return (
    <div
      className="bg-white rounded-lg p-4 w-80 justify-center items-center flex flex-col gap-y-4"
      data-testid="confirmation-modal"
    >
      <div className="flex">
        <button
          onClick={onDismiss}
          className="m-2 p-2 absolute top-0 right-0 rounded-lg hover:bg-cta-hover"
          data-testid="confirmation-modal-button-dismiss-button"
        >
          <Icon name="cross" width={12} height={12} />
        </button>
        <span
          className="bg-background rounded-lg p-3"
          data-testid="confirmation-modal-icon"
        >
          <Icon
            name={icon}
            pathFill={iconFill}
            width={iconHeight ?? 14}
            height={iconHeight ?? 14}
          />
        </span>
      </div>

      <span
        className="text-lg font-semibold"
        data-testid="confirmation-modal-title"
      >
        {title}
      </span>
      <div
        className="flex flex-col text-center"
        data-testid="confirmation-modal-text"
      >
        {subTexts.map((subText, i) => (
          <span key={i}>{subText}</span>
        ))}
      </div>
      <div className="flex w-full" data-testid="confirmation-modal-text">
        {!loading && (
          <input
            type={inputType}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className=" w-full border-0.5 border-coolGray-400 rounded-sm"
          />
        )}
      </div>
      <div
        className="flex w-full gap-x-2"
        data-testid="confirmation-modal-ctas"
      >
        {loading && <Spinner />}
        {!loading && dismissText && (
          <CTA type="secondary" onClick={onDismiss}>
            {dismissText}
          </CTA>
        )}
        {!loading && submitText && (
          <CTA type="primary" onClick={() => onSubmit(inputText)}>
            {submitText}
          </CTA>
        )}
      </div>
    </div>
  );
};
