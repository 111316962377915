/* eslint-disable no-restricted-syntax */
import { combineLatest, filter, iif, mergeMap, ReplaySubject } from 'rxjs';

import {
  getLocalItem,
  observeLocalItem,
  removeLocalItem,
  setLocalItem,
} from './local';
import {
  getSessionItem,
  observeSessionItem,
  removeSessionItem,
  setSessionItem,
} from './session';

type AuthKey =
  | 'ACCESS_TOKEN'
  | 'REFRESH_TOKEN'
  | 'BAK_ACCESS_TOKEN'
  | 'BAK_REFRESH_TOKEN';
type Flag = 'REFRESH_IN_PROGRESS' | 'use_flag';
type UnknownKey = string & {};
export type PossibleStorageKey = AuthKey | Flag | UnknownKey;

let onUnloadCallbacks: Record<string, () => void> = {};

export const observeItem = <T>(
  key: PossibleStorageKey,
): ReplaySubject<T | undefined> => {
  const sessionObs = observeSessionItem<T>(key);
  const localObs = observeLocalItem<T>(key);

  return combineLatest([sessionObs, localObs]).pipe(
    mergeMap(() => iif(sessionStorageInUse, sessionObs, localObs)),
  ) as ReplaySubject<T | undefined>;
};

export const toggleSessionStorage = () => {
  setSessionItem('use_flag', true);
};

export const toggleLocalStorage = () => {
  removeSessionItem('use_flag');
};

export const sessionStorageInUse = () => {
  return !!getSessionItem('use_flag');
};

export const getItem = <T = any>(key: PossibleStorageKey): T | undefined => {
  return sessionStorageInUse() ? getSessionItem(key) : getLocalItem(key);
};

export const setItem = (key: PossibleStorageKey, value: any) => {
  if (sessionStorageInUse()) setSessionItem(key, value);
  else setLocalItem(key, value);
};

export const removeItem = (key: PossibleStorageKey) => {
  if (sessionStorageInUse()) removeSessionItem(key);
  else removeLocalItem(key);
};

/**
 * Hook into the beforeunload event to allow us to run a callback when the tab is about to be destroyed
 * @param {() => void} callback - The callback to run when the tab is about to be destroyed
 * @param {string} identifier - The identifier later used for removing the callback
 * @returns {void}
 */
export const onUnload = (callback: () => void, identifier: string) => {
  onUnloadCallbacks[identifier] = callback;

  // 'beforeunload' is apparently the most reliable way to detect whether a tab is about to close (be destroyed)
  // it does potentially affect the bfcache, but we should only hook into this event
  // when absolutely necessary (such as when we are requesting a new access token)
  window.addEventListener('beforeunload', callback);
};

/**
 * Remove the callback linked to the identifier for the beforeunload event
 * @param {string} identifier - The identifier previously used for adding the callback
 * @returns {bool} Whether the identified callback existed and was removed
 */
export const removeUnloadEvent = (identifier: string): boolean => {
  if (!onUnloadCallbacks[identifier]) return false;

  window.removeEventListener('beforeunload', onUnloadCallbacks[identifier]);
  return true;
};

export const setCookie = (cookie: string) => {
  document.cookie = cookie;
};

export const checkForCookie = (cookieName: string) => {
  return !!document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1];
};

export const removeCookie = (cookieName: string) => {
  if (checkForCookie(cookieName)) {
    document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};
