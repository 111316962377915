import { createSafeContext } from 'kantan-utils';
import { PropsWithChildren } from 'react';

import { usePermissions } from './hooks/usePermissions';
import { useSessionUrl } from './hooks/useSessionUrl';

export const [AuthorizationContext, useAuthorization] = createSafeContext<{
  permissions: ReturnType<typeof usePermissions>;
  sessionUrl?: string;
  setSessionUrl: (url?: string) => void;
}>('Authorization');

interface AuthorizationProviderProps {}

export const AuthorizationProvider = ({
  children,
}: PropsWithChildren<AuthorizationProviderProps>) => {
  const [sessionUrl, setSessionUrl] = useSessionUrl();
  const permissions = usePermissions();

  return (
    <AuthorizationContext.Provider
      value={{
        permissions,
        sessionUrl,
        setSessionUrl,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
